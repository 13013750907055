import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useCreateCheckoutSessionMutation } from '../../hooks/api/checkout/checkout.generated';
import { useGetSubscriptionPlansQuery } from '../../hooks/api/subscriptionPlans/subscriptionPlans.generated';
import { LoadingSpinner } from '../Utils';
import LoadingButton from '../Utils/LoadingButtons';
import ErrorSnackbar from '../Utils/ErrorSnackbar';

export function SubscriptionPlansList() {
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState('');

  const { data, loading, error } = useGetSubscriptionPlansQuery({
    variables: {},
  });

  const [createCheckoutSessionMutation] = useCreateCheckoutSessionMutation();

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorSnackbar message="Failed to load subscription plans" />;

  const handleCheckout = async (planId: string) => {
    if (!stripe || !planId) return;

    try {
      const result = await createCheckoutSessionMutation({
        variables: { planId },
      });

      const sessionId = result.data?.createCheckoutSession?.sessionId;
      if (sessionId) {
        await stripe.redirectToCheckout({ sessionId });
      } else {
        throw new Error('Failed to create checkout session');
      }
    } catch (err) {
      console.error('Error:', err);
      setErrorMessage('Failed to initiate checkout. Please try again.');
    }
  };

  const renderPlanFeatures = (features: any) => (
    <List>
      {Object.entries(features)
        .filter(([key]) => key !== '__typename')
        .map(([key, value]) => (
          <ListItem key={key}>
            <ListItemText primary={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`} />
          </ListItem>
        ))}
    </List>
  );

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {data?.getSubscriptionPlans.subscriptionPlans.map((plan) => (
          <Box
            key={plan.id}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            padding={3}
            margin={2}
            border={1}
            borderRadius={2}
            borderColor="grey.300"
            width="300px"
            height="600px"
          >
            <Box>
              <Typography variant="h5" component="h3" gutterBottom>
                {plan.name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {plan.description}
              </Typography>
            </Box>

            {renderPlanFeatures(plan.features)}

            <Box>
              <Typography variant="h4" gutterBottom>
                Price: {plan.price} {plan.currency}
              </Typography>
              <LoadingButton
                color="primary"
                onClick={() => handleCheckout(plan.id)}
                style={{ marginTop: '16px' }}
                variant="contained"
              >
                Checkout
              </LoadingButton>
            </Box>
          </Box>
        ))}
      </Box>
      <ErrorSnackbar message={errorMessage} onClose={() => setErrorMessage('')} />
    </Container>
  );
}
