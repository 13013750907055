import { Box, Button, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export interface ActionTypeButtonProps {
  label: string;
  description: string;
  disabled?: boolean;
  onClick?: () => void;
  registerFieldName: string;
  registerFieldValue: any;
}

export const ActionTypeButton: React.FC<ActionTypeButtonProps> = ({
  label,
  description,
  disabled,
  registerFieldName,
  registerFieldValue,
  onClick,
  ...props
}) => {
  const methods = useFormContext();

  const handleClick = () => {
    methods.setValue(registerFieldName, registerFieldValue);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Controller
      control={methods.control}
      name={registerFieldName}
      render={() => (
        <Button
          {...props}
          disabled={disabled}
          onClick={handleClick}
          variant="outlined"
          type="button"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="subtitle1">{label}</Typography>
            <Typography variant="caption">{description}</Typography>
          </Box>
        </Button>
      )}
    />
  );
};
