// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/components/ActionConfig.tsx

import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { ConfigureFormValues } from '../validation';
import { TextInput } from '../../../../Inputs';

export const ActionConfig: React.FC<{
  register: UseFormRegister<ConfigureFormValues>;
  errors: FieldErrors<ConfigureFormValues>;
  setValue: UseFormSetValue<ConfigureFormValues>;
}> = ({ register, errors }) => {
  return (
    <Box>
      <Typography gutterBottom variant="subtitle1">
        Action Settings
      </Typography>
      <Stack spacing={2}>
        <TextInput
          {...register('action.buttonLabel')}
          errors={errors}
          fullWidth
          label="Button Label"
          name="action.buttonLabel"
        />
        <TextInput
          {...register('action.disclaimerText')}
          errors={errors}
          fullWidth
          label="Disclaimer Text"
          name="action.disclaimerText"
        />
      </Stack>
    </Box>
  );
};
