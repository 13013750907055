import React, { useState } from 'react';
import { Alert, Box, Container, Link, Paper, Typography } from '@mui/material';
import { appConfig } from '../../app-config';

const VerifyEmail: React.FC = () => {
  const [emailSent, setEmailSent] = useState(false);

  const handleVerifyEmail = () => {
    // TODO: Implement logic to send verification email
    setEmailSent(true);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Closer2
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Verify Email
        </Typography>
        <Box sx={{ mt: 2, width: '100%' }}>
          {emailSent ? (
            <Alert severity="info" sx={{ mb: 2 }}>
              An email has been sent to your inbox. Please follow the instructions to verify your
              email address. You can close this tab now.
            </Alert>
          ) : (
            <>
              <Typography variant="h5" gutterBottom align="center">
                An email has been sent to your inbox.
              </Typography>
              <Typography variant="h6" gutterBottom align="center">
                Please follow the instructions to verify your email address.
              </Typography>
              {/* <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleVerifyEmail}
                sx={{ mt: 2, mb: 2 }}
              >
                Resend Email
              </Button> */}
            </>
          )}
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Did not receive the email? Check your spam folder or{' '}
            <Link href={`mailto:${appConfig.supportEmail}`} target="_blank" rel="noopener">
              contact support
            </Link>
            .
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default VerifyEmail;
