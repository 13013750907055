import { useEffect, FC, SetStateAction, Dispatch } from 'react';
import {
  Avatar,
  List,
  ListItemText,
  Grid,
  ListItem,
  ListItemAvatar,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { DragHandleIcon, EmailIcon, TrashIcon } from '../../assets/icons';
import { ActionData } from './ActionData.model';

interface ActionsEditableListProps {
  actions: ActionData[];
  setActions: Dispatch<SetStateAction<ActionData[]>>;
}

export const ActionsEditableList: FC<ActionsEditableListProps> = ({ actions, setActions }) => {
  console.log('Rendering ActionsEditableList with actions:', actions);

  // const [actions, setActions] = useState<ActionData[]>([]);

  useEffect(() => {
    setActions(actions || []);
  }, [actions]);

  const handleRemoveItem = (index: number) => {
    const updatedActions = [...actions];
    updatedActions.splice(index, 1);
    setActions(updatedActions);
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    // Dropped at the same place
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Create a new array with the updated order
    const updatedActions = Array.from(actions);
    const [removed] = updatedActions.splice(source.index, 1);
    updatedActions.splice(destination.index, 0, removed);

    // Update the order property of each action
    const updatedActionsWithOrder = updatedActions.map((action, index) => {
      return { ...action, order: index };
    });

    // Update the state with the new array
    setActions(updatedActionsWithOrder);
  };

  return (
    <div>
      <Grid item xs={12} md={6}>
        <div>
          <List dense>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {actions.map((action, index) => (
                      <Draggable
                        key={action.order}
                        draggableId={String(action.order)}
                        index={index}
                      >
                        {(innerProvided) => (
                          <ListItem
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                            {...innerProvided.dragHandleProps}
                            secondaryAction={
                              <IconButton
                                aria-label="remove"
                                edge="end"
                                onClick={() => handleRemoveItem(index)}
                              >
                                <TrashIcon />
                              </IconButton>
                            }
                          >
                            <ListItemIcon {...innerProvided.dragHandleProps}>
                              <DragHandleIcon />
                            </ListItemIcon>
                            <ListItemAvatar>
                              <Avatar>
                                <EmailIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={action.label} secondary={action.type} />
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </List>
        </div>
      </Grid>
    </div>
  );
};

export default ActionsEditableList;
