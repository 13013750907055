// src/components/Common/ControllerSelect.tsx

import React, { memo } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Theme,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { ControllersQuery } from '../../hooks/api/controllers/controllers.generated';

export interface ControllerSelectProps {
  selectedControllerId?: string;
  controllersData?: ControllersQuery;
  onControllerChange: (value: string) => void;
  disabled?: boolean;
  label?: string;
}

const ControllerSelect = memo(
  ({
    selectedControllerId = '',
    controllersData,
    onControllerChange,
    disabled = false,
    label = 'Select a Controller',
  }: ControllerSelectProps) => {
    const theme = useTheme<Theme>();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const handleChange = (event: SelectChangeEvent) => {
      onControllerChange(event.target.value);
    };

    return (
      <Box>
        <FormControl disabled={disabled} fullWidth sx={{ minWidth: { xs: '100%', md: 300 } }}>
          <InputLabel id="controller-select-label">{label}</InputLabel>
          <Select
            id="controllerSelect"
            label={label}
            labelId="controller-select-label"
            onChange={handleChange}
            sx={{
              px: { xs: 2, md: 11.25 },
              maxHeight: '56px',
              '& .MuiSelect-select': {
                py: 2,
              },
            }}
            value={selectedControllerId}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {controllersData?.controllers.controllers.map((controller) => (
              <MenuItem key={controller.id} value={controller.id}>
                {controller.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  },
);

export default ControllerSelect;
