import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from '../styles/theme';

type SidebarItemProps = {
  item: {
    path: string;
    icon: React.ElementType;
    label: string;
  };
  onItemClick?: () => void;
};

const SidebarItem = React.memo(({ item, onItemClick }: SidebarItemProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(item.path);
    if (onItemClick) {
      onItemClick();
    }
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: pathname.startsWith(item.path)
            ? 'rgba(255, 255, 255, 0.10)'
            : theme.palette.primary.main,
          borderRadius: '4px',
          marginRight: 2,
        }}
      >
        <ListItemIcon
          sx={{
            height: '20px',
            width: '20px',
            minWidth: '20px',
            marginRight: '10px',
            marginBottom: '2px',
          }}
        >
          <item.icon fill={pathname.startsWith(item.path) ? '#00DF81' : 'white'} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="subtitle2">{item.label}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
});

export default SidebarItem;
