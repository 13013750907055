import React from 'react';
import { Snackbar, Alert, SnackbarProps } from '@mui/material';

interface ErrorSnackbarProps extends Omit<SnackbarProps, 'open' | 'onClose'> {
  message: string;
  open?: boolean;
  onClose?: () => void;
}

const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  message,
  open,
  onClose,
  ...snackbarProps
}) => {
  const isOpen = open !== undefined ? open : Boolean(message);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose?.();
  };

  return (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open={isOpen} {...snackbarProps}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        Error: Connecting to backend. [{message}]
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
