import React, { useState, useCallback } from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  Drawer,
  Alert,
  Tooltip,
  Divider,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { theme } from '../styles/theme';
import {
  Closer2Icon,
  CustomerIcon,
  LogoutIcon,
  MenuIcon,
  CloseIcon,
  SettingsIcon,
  HelpIcon,
} from '../assets/icons';
import PanelSidebar from './PanelSidebar';

const drawerWidth = 240;

const PanelLayout = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logout, user } = useAuth0();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);

  const handleUserMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(event.currentTarget);
  }, []);

  const handleUserMenuClose = useCallback(() => {
    setUserMenuAnchor(null);
  }, []);

  const handleMobileMenuToggle = useCallback(() => {
    setMobileMenuOpen((prev) => !prev);
  }, []);

  const handleLogoutClick = useCallback(() => {
    handleUserMenuClose();
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout, handleUserMenuClose]);

  const handleSettingsClick = useCallback(() => {
    handleUserMenuClose();
    navigate('/settings');
  }, [navigate, handleUserMenuClose]);

  const handleHelpClick = useCallback(() => {
    handleUserMenuClose();
    navigate('/support');
  }, [navigate, handleUserMenuClose]);

  const handleMyAccountClick = useCallback(() => {
    handleUserMenuClose();
    navigate('/profile');
  }, [navigate, handleUserMenuClose]);

  const handleMobileMenuClose = useCallback(() => {
    setMobileMenuOpen(false);
  }, []);

  const renderDesktopActions = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip title="Settings">
        <IconButton onClick={handleSettingsClick} sx={{ color: 'text.primary' }}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Help">
        <IconButton onClick={handleHelpClick} sx={{ color: 'text.primary' }}>
          <HelpIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={user?.email || 'Account'}>
        <IconButton onClick={handleUserMenuOpen} sx={{ color: 'text.primary' }}>
          <CustomerIcon fill="black" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={userMenuAnchor}
        open={Boolean(userMenuAnchor)}
        onClose={handleUserMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disabled>
          <Typography variant="body2" color="text.secondary">
            {user?.email}
            <Divider />
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleMyAccountClick}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <CustomerIcon fill="black" />
            My Account
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <LogoutIcon />
            Log Out
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );

  const renderMobileActions = () => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton onClick={handleUserMenuOpen} color="inherit">
        <CustomerIcon />
      </IconButton>
      <Menu anchorEl={userMenuAnchor} open={Boolean(userMenuAnchor)} onClose={handleUserMenuClose}>
        <MenuItem disabled>
          <Typography variant="body2" color="text.secondary">
            {user?.email}
            <Divider />
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleSettingsClick}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <SettingsIcon />
            Settings
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleHelpClick}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <HelpIcon />
            Help
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <LogoutIcon />
            Log Out
          </Typography>
        </MenuItem>
      </Menu>

      <IconButton
        aria-label="open drawer"
        color="inherit"
        edge="start"
        onClick={handleMobileMenuToggle}
        sx={{ ml: 1 }}
      >
        {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: {
            xs: theme.palette.primary.main,
            sm: theme.palette.background.default,
          },
          boxShadow: { sm: 'none' },
          borderBottom: { sm: '1px solid rgba(0, 0, 0, 0.12)' },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
          }}
        >
          {isMobile && (
            <Box sx={{ height: '17px', width: '96px' }}>
              <Closer2Icon />
            </Box>
          )}

          {isMobile ? renderMobileActions() : renderDesktopActions()}

          {user && !user.email_verified && (
            <Alert severity="warning">Please verify your email</Alert>
          )}
        </Toolbar>
      </AppBar>

      {isMobile ? (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            zIndex: theme.zIndex.drawer + 1,
            transform: mobileMenuOpen ? 'translateX(0)' : 'translateX(-100%)',
            transition: theme.transitions.create('transform', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Box sx={{ marginTop: '54px' }}>
            <PanelSidebar drawerWidth="100%" isMobile onItemClick={handleMobileMenuClose} />
          </Box>
        </Box>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <PanelSidebar drawerWidth={drawerWidth} isMobile={false} />
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop: { xs: '56px', sm: '64px' },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(PanelLayout);
