// src/components/Profile/components/utils/InfoSection.tsx

import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';

interface InfoSectionProps {
  icon: React.ReactNode;
  title: string;
  content: string;
}

export const InfoSection = memo(({ icon, title, content }: InfoSectionProps) => (
  <Box mb={2}>
    <Typography
      gutterBottom
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
      variant="h6"
    >
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      {title}
    </Typography>
    <Typography
      paragraph
      sx={{
        wordBreak: 'break-word',
        color: 'text.secondary',
        pl: '32px', // Aligns with icon
      }}
      variant="body2"
    >
      {content}
    </Typography>
  </Box>
));
