// src/components/Usage/components/UsageMetrics.tsx
import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { UsageMetricsProps } from '../types';
import { UsagePieChart } from './UsagePieChart';

export const UsageMetrics = memo(({ title, total, used, remaining }: UsageMetricsProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      gap: 4,
      mb: 4,
      minHeight: { xs: '200px', md: '300px' },
    }}
  >
    <Box
      sx={{
        width: { xs: '100%', md: '45%' },
        height: { xs: '200px', md: '300px' },
      }}
    >
      <UsagePieChart
        colors={['#0088FE', '#00C49F']}
        data={[
          { name: 'Used', value: used },
          { name: 'Remaining', value: remaining },
        ]}
      />
    </Box>

    <Box
      sx={{
        width: { xs: '100%', md: '45%' },
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
        py: { xs: 1, md: 2 },
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Box>
        <Typography>Total Allowed: {total}</Typography>
        <Typography>Used: {used}</Typography>
        <Typography>Remaining: {remaining}</Typography>
      </Box>
    </Box>
  </Box>
));
