import React from 'react';
import { Box, Button, TextField } from '@mui/material';

interface DateRangePickerProps {
  startDate: string;
  endDate: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  onUpdate: () => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onUpdate,
}) => (
  <Box display="flex" alignItems="center" gap={2}>
    <TextField
      label="Start Date"
      type="date"
      value={startDate}
      onChange={(e) => onStartDateChange(e.target.value)}
      InputLabelProps={{ shrink: true }}
    />
    <TextField
      label="End Date"
      type="date"
      value={endDate}
      onChange={(e) => onEndDateChange(e.target.value)}
      InputLabelProps={{ shrink: true }}
    />
    <Button variant="contained" onClick={onUpdate}>
      Update
    </Button>
  </Box>
);

export default DateRangePicker;
