// src/components/EditController/components/OpenControllerSection.tsx

import React, { useEffect } from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ConfigureFormValues,
  configureSchema,
} from '../../CreateNewControllerWizardV2/steps/ConfigureStep/validation';
import { useWizard } from '../../CreateNewControllerWizardV2/context/WizardContext';
import { ControllerBasicInfo } from '../../CreateNewControllerWizardV2/steps/ConfigureStep/components/ControllerBasicInfo';
import { ActionConfig } from '../../CreateNewControllerWizardV2/steps/ConfigureStep/components/ActionConfig';
import { WidgetPreview } from '../../CreateNewControllerWizardV2/shared/WidgetPreview';

export const OpenControllerSection: React.FC = () => {
  const { state, dispatch } = useWizard();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
  } = useForm<ConfigureFormValues>({
    resolver: yupResolver(configureSchema),
    defaultValues: {
      name: state.draft?.name || '',
      action: {
        buttonLabel: state.draft?.stages[0]?.actions[0]?.label || '',
        disclaimerText: state.draft?.stages[0]?.actions[0]?.emailCollection?.disclaimerText || '',
      },
    },
    mode: 'onChange',
  });

  // Watch individual fields
  const name = watch('name');
  const buttonLabel = watch('action.buttonLabel');
  const disclaimerText = watch('action.disclaimerText');

  // Update draft
  useEffect(() => {
    if (!state.draft) return;

    const updatedDraft = {
      ...state.draft,
      name,
      stages: state.draft.stages.map((stage) => ({
        ...stage,
        actions: stage.actions.map((action) => ({
          ...action,
          label: buttonLabel,
          emailCollection: {
            ...action.emailCollection!,
            disclaimerText,
          },
        })),
      })),
    };

    // Direct draft update for immediate UI feedback
    dispatch({ type: 'SET_DRAFT', payload: updatedDraft });
  }, [name, buttonLabel, disclaimerText]);

  // Track form validation separately
  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        configure: {
          handleSubmit: () => Promise.resolve(),
          isSubmitting,
          isValid,
        },
      },
    });

    return () => {
      dispatch({
        type: 'SET_FORM_HANDLERS',
        payload: {
          configure: undefined,
        },
      });
    };
  }, [isSubmitting, isValid]);

  // Track form validation state
  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        configure: {
          handleSubmit: handleSubmit((data) => Promise.resolve(data)),
          isSubmitting,
          isValid,
        },
      },
    });

    return () => {
      dispatch({
        type: 'SET_FORM_HANDLERS',
        payload: {
          configure: undefined,
        },
      });
    };
  }, [isSubmitting, isValid, handleSubmit]);

  const previewState = {
    draftController: state.draft ?? undefined,
    controllerId: state.controllerId!,
    previewKey: state.previewKey,
    isSaving: state.isSaving,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <ControllerBasicInfo errors={errors} register={register} />
          </Paper>

          <Paper elevation={3} sx={{ p: 3 }}>
            <ActionConfig errors={errors} register={register} setValue={setValue} />
          </Paper>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            position: 'sticky',
            top: 24,
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
          }}
        >
          <WidgetPreview fullscreenMode previewState={previewState} />
        </Paper>
      </Grid>
    </Grid>
  );
};
