import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CustomerQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CustomerQuery = {
  __typename?: 'Query';
  customer: {
    __typename?: 'CustomerType';
    id: string;
    clientId: string;
    name?: string | null;
    stripeId: string;
    companyName?: string | null;
    taxId?: string | null;
    country?: string | null;
    city?: string | null;
    postalCode?: string | null;
    address?: string | null;
    email: string;
    phoneNumber?: string | null;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    isEmailVerified?: boolean | null;
    isNewUser?: boolean | null;
    status?: string | null;
    customerSettings?: {
      __typename?: 'UserSettingsType';
      clientId: string;
      trustedDomains: Array<string>;
      crmIntegration: {
        __typename?: 'CRMIntegrationType';
        freshworks?: {
          __typename?: 'FreshworksCRMIntegrationType';
          isActive: boolean;
          apiKey?: string | null;
        } | null;
      };
    } | null;
    currentSubscription?: {
      __typename?: 'CustomerSubscriptionType';
      status: string;
      startDate: string;
      endDate: string;
      plan: { __typename?: 'PlanType'; id: string; name: string };
      limits: {
        __typename?: 'LimitsType';
        viewLimit: number;
        playLimit: number;
        domainLimit: number;
        videoLimit: number;
        controllerLimit: number;
        actionLimit: number;
      };
      cancellation?: {
        __typename?: 'CancellationType';
        cancelledAt?: string | null;
        cancelAt?: string | null;
      } | null;
      usage: { __typename?: 'UsageType'; views: number; plays: number; actions: number };
    } | null;
    subscriptionHistory?: Array<{
      __typename?: 'CustomerSubscriptionType';
      status: string;
      startDate: string;
      endDate: string;
      plan: { __typename?: 'PlanType'; id: string; name: string };
      limits: {
        __typename?: 'LimitsType';
        viewLimit: number;
        playLimit: number;
        domainLimit: number;
        videoLimit: number;
        controllerLimit: number;
        actionLimit: number;
      };
      usage: { __typename?: 'UsageType'; views: number; plays: number; actions: number };
    }> | null;
    resources?: {
      __typename?: 'CustomerResourcesType';
      controllers: {
        __typename?: 'ResourceUsageType';
        current: number;
        limit: number;
        remaining: number;
      };
      videos: {
        __typename?: 'ResourceUsageType';
        current: number;
        limit: number;
        remaining: number;
      };
      domains: {
        __typename?: 'ResourceUsageType';
        current: number;
        limit: number;
        remaining: number;
      };
    } | null;
  };
};

export const CustomerDocument = gql`
  query customer($id: String) {
    customer(id: $id) {
      id
      clientId
      name
      stripeId
      companyName
      taxId
      country
      city
      postalCode
      address
      email
      phoneNumber
      createdAt
      updatedAt
      deletedAt
      isEmailVerified
      isNewUser
      status
      customerSettings {
        clientId
        trustedDomains
        crmIntegration {
          freshworks {
            isActive
            apiKey
          }
        }
      }
      currentSubscription {
        status
        plan {
          id
          name
        }
        limits {
          viewLimit
          playLimit
          domainLimit
          videoLimit
          controllerLimit
          actionLimit
        }
        cancellation {
          cancelledAt
          cancelAt
        }
        startDate
        endDate
        usage {
          views
          plays
          actions
        }
      }
      subscriptionHistory {
        status
        plan {
          id
          name
        }
        limits {
          viewLimit
          playLimit
          domainLimit
          videoLimit
          controllerLimit
          actionLimit
        }
        startDate
        endDate
        usage {
          views
          plays
          actions
        }
      }
      resources {
        controllers {
          current
          limit
          remaining
        }
        videos {
          current
          limit
          remaining
        }
        domains {
          current
          limit
          remaining
        }
      }
    }
  }
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export function useCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export function refetchCustomerQuery(variables?: CustomerQueryVariables) {
  return { query: CustomerDocument, variables };
}
