import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type PatchCustomerSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateUserSettingsTypeInput;
}>;

export type PatchCustomerSettingsMutation = {
  __typename?: 'Mutation';
  patchCustomerSettings: {
    __typename?: 'UserSettingsType';
    trustedDomains: Array<string>;
    crmIntegration: {
      __typename?: 'CRMIntegrationType';
      freshworks?: {
        __typename?: 'FreshworksCRMIntegrationType';
        isActive: boolean;
        apiKey?: string | null;
      } | null;
    };
  };
};

export const PatchCustomerSettingsDocument = gql`
  mutation PatchCustomerSettings($input: UpdateUserSettingsTypeInput!) {
    patchCustomerSettings(input: $input) {
      trustedDomains
      crmIntegration {
        freshworks {
          isActive
          apiKey
        }
      }
    }
  }
`;
export type PatchCustomerSettingsMutationFn = Apollo.MutationFunction<
  PatchCustomerSettingsMutation,
  PatchCustomerSettingsMutationVariables
>;

/**
 * __usePatchCustomerSettingsMutation__
 *
 * To run a mutation, you first call `usePatchCustomerSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchCustomerSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchCustomerSettingsMutation, { data, loading, error }] = usePatchCustomerSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchCustomerSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchCustomerSettingsMutation,
    PatchCustomerSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchCustomerSettingsMutation, PatchCustomerSettingsMutationVariables>(
    PatchCustomerSettingsDocument,
    options,
  );
}
export type PatchCustomerSettingsMutationHookResult = ReturnType<
  typeof usePatchCustomerSettingsMutation
>;
export type PatchCustomerSettingsMutationResult =
  Apollo.MutationResult<PatchCustomerSettingsMutation>;
export type PatchCustomerSettingsMutationOptions = Apollo.BaseMutationOptions<
  PatchCustomerSettingsMutation,
  PatchCustomerSettingsMutationVariables
>;
