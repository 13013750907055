// src/components/EditController/context/EditContext.tsx
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { GetControllerByIdQuery } from '../../../hooks/api/controller/controller.generated';
import { UpdateControllerMutation } from '../../../hooks/api/updateController/updateController.generated';
import { StyleConfig } from '../../Common/StyleComponents/types';

interface EditState {
  originalController: UpdateControllerMutation['updateController'] | null;
  pendingChanges: {
    videoAsset?: {
      fileId: string;
      name: string;
    };
    // We'll add more pending changes as we implement other tabs
  };
  activeTab: number;
  isSaving: boolean;
  error: Error | null;
  previewKey: number;
  videoChangeMode: 'current' | 'upload' | 'existing';
  styleChanges?: StyleConfig;
}

type EditAction =
  | { type: 'SET_ORIGINAL_CONTROLLER'; payload: UpdateControllerMutation['updateController'] }
  | { type: 'UPDATE_VIDEO'; payload: { fileId: string; name: string } | null }
  | { type: 'SET_ACTIVE_TAB'; payload: number }
  | { type: 'SET_SAVING'; payload: boolean }
  | { type: 'SET_VIDEO_MODE'; payload: EditState['videoChangeMode'] }
  | { type: 'SET_ERROR'; payload: Error | null }
  | { type: 'CLEAR_CHANGES' }
  | { type: 'TRIGGER_PREVIEW_REFRESH' }
  | { type: 'UPDATE_STYLE'; payload: Partial<StyleConfig> };

function editReducer(state: EditState, action: EditAction): EditState {
  switch (action.type) {
    case 'SET_ORIGINAL_CONTROLLER':
      return {
        ...state,
        originalController: action.payload,
        previewKey: state.previewKey + 1,
      };
    case 'UPDATE_VIDEO':
      return {
        ...state,
        pendingChanges: {
          ...state.pendingChanges,
          videoAsset: action.payload || undefined,
        },
        previewKey: state.previewKey + 1,
      };
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'SET_SAVING':
      return {
        ...state,
        isSaving: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'CLEAR_CHANGES':
      return {
        ...state,
        pendingChanges: {},
        previewKey: state.previewKey + 1,
      };
    case 'TRIGGER_PREVIEW_REFRESH':
      return {
        ...state,
        previewKey: state.previewKey + 1,
      };
    case 'UPDATE_STYLE':
      return {
        ...state,
        styleChanges: {
          ...state.styleChanges,
          ...action.payload,
        },
        previewKey: state.previewKey + 1,
      };
    default:
      return state;
  }
}

const EditContext = createContext<{
  state: EditState;
  dispatch: React.Dispatch<EditAction>;
} | null>(null);

interface EditProviderProps {
  children: React.ReactNode;
  controller: GetControllerByIdQuery['controller'];
}

export const EditProvider: React.FC<EditProviderProps> = ({ children, controller }) => {
  // Initialize state from controller data
  const initialState: EditState = {
    originalController: controller,
    pendingChanges: {},
    activeTab: 0,
    isSaving: false,
    error: null,
    previewKey: 0,
    videoChangeMode: 'current',
  };

  const [state, dispatch] = useReducer(editReducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state]);

  return <EditContext.Provider value={contextValue}>{children}</EditContext.Provider>;
};

export const useEditContext = () => {
  const context = useContext(EditContext);
  if (!context) {
    throw new Error('useEditContext must be used within an EditProvider');
  }
  return context;
};
