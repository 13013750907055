import React, { RefObject, useEffect, useState } from 'react';
import { ReplayIcon } from '../../assets/icons';
import { AddNewControllerValues } from '../Controllers/videoControllersSchema';
import MobileMockup from '../Mockups/MobileMockup';

export interface VideoTagProps {
  movieUrl?: string;
  // videoRef: RefObject<HTMLVideoElement>;
  isOpen: boolean;
  data: Partial<AddNewControllerValues>;
}

const WidgetControllerPreview = ({ movieUrl, data, isOpen }: VideoTagProps) => {
  const videoRef = React.createRef<HTMLVideoElement>();

  const [showOverlay, setShowOverlay] = useState(false);

  const handleReplayClick = () => {
    setShowOverlay(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  const doRewind = () => false;

  // if (
  //   data?.stages &&
  //   data.stages[data.initialStageId ?? '0'] &&
  //   data.stages[data.initialStageId ?? '0'].content.videoAssetId
  // ) {
  return (
    <MobileMockup />
    // <p>
    //   {`https://cdn.eu-west-1.dev.closer2.io/${
    //     data.stages[data.initialStageId ?? '0'].content.videoAssetId
    //   }`}
    // </p>
    // <>
    //   <video
    //     key={`https://cdn.eu-west-1.dev.closer2.io/${
    //       data.stages[data.initialStageId ?? '0'].content.videoAssetId
    //     }`}
    //     ref={videoRef}
    //     autoPlay
    //     loop={!isOpen}
    //     muted={!isOpen}
    //     onEnded={() => {
    //       if (!videoRef.current) {
    //         return;
    //       }
    //       if (doRewind()) {
    //         videoRef.current.currentTime = 0;
    //         videoRef.current.play();
    //       } else {
    //         setShowOverlay(true);
    //       }
    //       // if (!isOpen || !data) {
    //       //   return;
    //       // }
    //     }}
    //     playsInline
    //     style={{
    //       backgroundColor: '#441078',
    //       position: 'relative',
    //       transition: 'all 0.25s ease-in-out 0s',
    //       borderRadius: isOpen ? '24px' : '50%',
    //       objectFit: isOpen ? 'contain' : 'cover',
    //       objectPosition: 'top',
    //       boxSizing: 'border-box',
    //       height: '100%',
    //       width: '100%',
    //     }}
    //   >
    //     <source
    //       src={`https://cdn.eu-west-1.dev.closer2.io/${
    //         data.stages[data.initialStageId ?? '0'].content.videoAssetId
    //       }`}
    //       type="video/mp4"
    //     />
    //     <track kind="captions" label="English" srcLang="en" />
    //   </video>
    //   {showOverlay && isOpen && (
    //     // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    //     <div
    //       onClick={handleReplayClick}
    //       style={{
    //         position: 'absolute',
    //         top: 0,
    //         left: 0,
    //         width: '100%',
    //         height: '100%',
    //         backgroundColor: 'rgba(0, 0, 0, 0.5)',
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         borderRadius: isOpen ? '24px' : '50%',
    //       }}
    //     >
    //       <ReplayIcon />
    //     </div>
    //   )}
    // </>
  );
  // }
  // return null;
};

export default WidgetControllerPreview;
