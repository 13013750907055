import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

interface CustomLabelInputProps {
  label: string;
  disabled?: boolean;
  values: string[];
  value: string;
  onChange: (value: string) => void;
  errors?: any;
  name?: string;
}

const CustomToggleButtonGroup = React.forwardRef<MouseEvent, CustomLabelInputProps>(
  ({ label, disabled, value, onChange, values, errors, name, ...props }) => {
    const [alignment, setAlignment] = useState<string | null>(value);

    const handleChange = (event: React.MouseEvent, newValue: string) => {
      setAlignment(newValue);
      onChange(newValue);
    };

    const fieldError = errors && errors[name ?? ''];
    return (
      <Box>
        <FormControl fullWidth>
          <Typography pb={1}>{label}</Typography>
          <ToggleButtonGroup
            color="primary"
            onChange={handleChange}
            value={alignment}
            exclusive
            aria-label="Platform"
            disabled={disabled}
          >
            {values.map((v) => (
              <ToggleButton key={v} value={v}>
                {v}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormControl>
        {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
      </Box>
    );
  },
);

export default CustomToggleButtonGroup;
