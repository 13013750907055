import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { usePatchCustomerSettingsMutation } from '../hooks/api/patchCustomerSettings/patchCustomerSettings.generated';
import { CustomerType, UserSettingsType } from '../interfaces';
import { useCustomerQuery } from '../hooks/api/customer/customer.generated';

type CustomerSettingsProviderProps = {
  children: React.ReactNode;
};

interface UpdateSettingsInput {
  trustedDomains?: string[];
  // Add future settings fields here
  // example: notificationPreferences?: { email: boolean; push: boolean };
}

type SettingsContextType = {
  customer: CustomerType | null;
  settings: UserSettingsType | null;
  isLoading: boolean;
  error: Error | undefined;
  updateSettings: (input: UpdateSettingsInput) => Promise<void>;
  refetchCustomer: () => Promise<void>;
};

const CustomerSettingsContext = createContext<SettingsContextType | null>(null);

export const SettingsProvider: React.FC<CustomerSettingsProviderProps> = ({ children }) => {
  const [customer, setCustomer] = useState<CustomerType | null>(null);

  const { data, loading: queryLoading, error: queryError, refetch } = useCustomerQuery();

  const [patchCustomerSettings, { loading: mutationLoading, error: mutationError }] =
    usePatchCustomerSettingsMutation();

  useEffect(() => {
    if (data?.customer) {
      setCustomer(data.customer);
    }
  }, [data]);

  const updateSettings = async (input: UpdateSettingsInput) => {
    try {
      await patchCustomerSettings({
        variables: {
          input,
        },
      });

      // Refetch to get updated data
      const { data: refreshedData } = await refetch();

      if (refreshedData?.customer) {
        setCustomer(refreshedData.customer);
      }
    } catch (error) {
      console.error('Failed to update settings:', error);
      throw error;
    }
  };

  const refetchCustomer = async () => {
    try {
      const { data: refreshedData } = await refetch();
      if (refreshedData?.customer) {
        setCustomer(refreshedData.customer);
      }
    } catch (error) {
      console.error('Failed to refetch customer:', error);
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      customer,
      settings: customer?.customerSettings ?? null,
      isLoading: queryLoading || mutationLoading,
      error: queryError || mutationError,
      updateSettings,
      refetchCustomer,
    }),
    [customer, queryLoading, mutationLoading, queryError, mutationError],
  );

  return (
    <CustomerSettingsContext.Provider value={contextValue}>
      {children}
    </CustomerSettingsContext.Provider>
  );
};

export const useCustomerSettings = () => {
  const context = useContext(CustomerSettingsContext);
  if (!context) {
    throw new Error('useCustomerSettings must be used within a SettingsProvider');
  }
  return context;
};
