// src/components/EditController/utils/mapController.ts
import { GetControllerByIdQuery } from '../../../hooks/api/controller/controller.generated';
import { ControllerDraft } from '../../CreateNewControllerWizardV2/types';

// TODO some neater solution would be better...
export const mapControllerToDraft = (
  controller: GetControllerByIdQuery['controller'],
): ControllerDraft => {
  return {
    id: controller.id,
    name: controller.name,
    isActive: controller.isActive,
    initialStageId: controller.initialStageId,
    stages: controller.stages.map((stage) => ({
      id: stage.id,
      name: stage.name || 'Unnamed Stage', // Handle nullable name
      content: {
        id: stage.content.id,
        type: stage.content.type,
        videoAssetId: stage.content.videoAssetId,
        videoAssetName: stage.content.videoAssetName,
        videoAssetUrl: stage.content.videoAssetUrl,
      },
      actions: stage.actions.map((action) => ({
        type: action.type,
        isFinal: action.isFinal,
        closeWidget: action.closeWidget,
        order: action.order,
        label: action.label,
        emailCollection: action.emailCollection
          ? {
              instructionsText: action.emailCollection.instructionsText,
              disclaimerText: action.emailCollection.disclaimerText,
              disclaimerUrl: action.emailCollection.disclaimerUrl || undefined,
            }
          : undefined,
      })),
    })),
    layout: {
      type: controller.layout?.type || 'widget',
      widget: {
        design: {
          minified: {
            widgetSize: (controller.layout?.widget?.design?.minified?.widgetSize || 'M') as
              | 'S'
              | 'M'
              | 'L',
            shape: (controller.layout?.widget?.design?.minified?.shape || 'circle') as
              | 'circle'
              | 'square'
              | 'rectangle',
            border: {
              enabled: controller.layout?.widget?.design?.minified?.border?.enabled ?? true,
              gradientColors: (controller.layout?.widget?.design?.minified?.border
                ?.gradientColors as [string, string]) || ['#00df81', '#653ffe'],
            },
            positioning: {
              position: (controller.layout?.widget?.design?.minified?.positioning?.position ||
                'bottomLeft') as 'bottomLeft' | 'bottomRight',
              bottomLeft: {
                marginBottom:
                  controller.layout?.widget?.design?.minified?.positioning?.bottomLeft
                    ?.marginBottom ?? 20,
                marginLeft:
                  controller.layout?.widget?.design?.minified?.positioning?.bottomLeft
                    ?.marginLeft ?? 20,
              },
              bottomRight: {
                marginBottom:
                  controller.layout?.widget?.design?.minified?.positioning?.bottomRight
                    ?.marginBottom ?? 20,
                marginRight:
                  controller.layout?.widget?.design?.minified?.positioning?.bottomRight
                    ?.marginRight ?? 20,
              },
            },
            addOns: {
              bubble: controller.layout?.widget?.design?.minified?.addOns?.bubble
                ? {
                    enabled: controller.layout.widget.design.minified.addOns.bubble.enabled,
                    text: controller.layout.widget.design.minified.addOns.bubble.text,
                    design: {
                      backgroundColor:
                        controller.layout.widget.design.minified.addOns.bubble.design
                          .backgroundColor,
                      textColor:
                        controller.layout.widget.design.minified.addOns.bubble.design.textColor,
                    },
                  }
                : undefined,
            },
          },
        },
      },
    },
  };
};
