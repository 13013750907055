// hooks/useControllers.ts
import { useMemo } from 'react';
import { useControllersQuery } from './api/controllers/controllers.generated';

export const useControllers = () => {
  const { data, loading, error } = useControllersQuery();

  const controllers = useMemo(() => {
    if (!data?.controllers.controllers) return [];

    return data.controllers.controllers.map((controller) => ({
      id: controller.id,
      customerId: controller.customerId,
      name: controller.name,
      previewImage: 'https://www.clapa.com/wp-content/uploads/2019/06/video-placeholder.jpg', // TODO: Replace with actual preview
      isActive: controller.isActive,
      validFrom: controller.validFrom,
      validUntil: controller.validUntil,
      url: controller.url,
      createdAt: controller.createdAt,
      updatedAt: controller.updatedAt,
    }));
  }, [data]);

  return {
    controllers,
    loading,
    error,
  };
};
