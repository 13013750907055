import React, { useEffect, useState } from 'react';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';
import { LoadingSpinner } from '../Utils';

const Message = ({ message }: { message: string }) => (
  <section>
    <p>{message}</p>
  </section>
);

const SuccessDisplay = ({ isPending }: { isPending: boolean }) => (
  <section>
    <div className="product Box-root">
      <div className="description Box-root">
        <h3>Subscription successful!</h3>
        {isPending ? (
          <>
            <LoadingSpinner />
            <p>{`We're setting up your account. This may take a few moments.`}</p>
          </>
        ) : (
          <p>Your account is now active. You can start using our services.</p>
        )}
      </div>
    </div>
  </section>
);

const PaymentSuccess: React.FC = () => {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [isPending, setIsPending] = useState(true);

  const { customer, isLoading, error: queryError, refetchCustomer } = useCustomerSettings();

  if (!customer) {
    throw new Error('Customer not found');
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      checkSubscriptionStatus();
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage('Order canceled.');
    }
  }, []);

  useEffect(() => {
    if (customer) {
      setIsPending(customer.status === 'pending');
    }
  }, [customer]);

  const checkSubscriptionStatus = async () => {
    try {
      await refetchCustomer();

      if (queryError) {
        throw new Error('Error fetching customer data');
      }

      console.log('refetch?', !isLoading && (!customer || customer.status === 'pending'));
      if (!isLoading && (!customer || customer.status === 'pending')) {
        console.log('refetching...');
        setTimeout(checkSubscriptionStatus, 5000); // Retry after 5 seconds
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      setSuccess(false);
      setMessage(
        'An error occurred while checking your subscription status. Please try refreshing the page or contact support.',
      );
    }
  };

  if (isLoading) {
    return <Message message="Loading..." />;
  }

  if (success) {
    return <SuccessDisplay isPending={isPending} />;
  }

  return (
    <Message
      message={
        message || 'Oops! Something went wrong. Please contact support at closer.io for assistance.'
      }
    />
  );
};

export default PaymentSuccess;
