// src/components/CreateNewControllerWizardV2/hooks/useStyleUpdates.ts

import { useCallback } from 'react';
import { debounce } from 'lodash';
import { usePatchControllerMutation } from '../../../../../hooks/api/patchController/patchController.generated';
import { StyleConfig } from '../../../types';
import { useWizard } from '../../../context/WizardContext';

export const useStyleUpdates = () => {
  const [patchController] = usePatchControllerMutation();
  const { state, dispatch } = useWizard();

  const updateController = useCallback(
    async (changes: Partial<StyleConfig>, path?: string, immediate = false) => {
      if (!state.controllerId) return;

      // Immediately update local state for better UX
      dispatch({ type: 'UPDATE_STYLE', payload: changes });
      dispatch({ type: 'TRIGGER_PREVIEW_REFRESH' });

      if (!immediate) {
        // Early return if not immediate - let the debounced function handle the API call
        return;
      }

      try {
        dispatch({ type: 'SET_SAVING', payload: true });

        const updates = {
          id: state.controllerId,
          changes: path
            ? [{ path, value: changes }]
            : Object.entries(changes).map(([key, value]) => ({
                path: `layout.widget.design.minified.${key}`,
                value,
              })),
        };

        const result = await patchController({
          variables: {
            input: updates,
          },
        });

        if (!result.data?.patchController) {
          // Revert local state if API call fails
          dispatch({ type: 'SET_ERROR', payload: new Error('Failed to update controller') });
        }
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error as Error });
      } finally {
        dispatch({ type: 'SET_SAVING', payload: false });
      }
    },
    [state.controllerId, patchController, dispatch],
  );

  const debouncedUpdate = useCallback(
    debounce((changes: Partial<StyleConfig>, path?: string) => {
      updateController(changes, path, true);
    }, 1000),
    [updateController],
  );

  return {
    updateStyle: (changes: Partial<StyleConfig>, path?: string, immediate = false) => {
      updateController(changes, path, immediate);
      if (!immediate) {
        debouncedUpdate(changes, path);
      }
    },
  };
};
