// src/components/Usage/UsageContainer.tsx

import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import ErrorBoundary from '../Utils/ErrorBoundry';
import { useGetUsageQuery } from '../../hooks/api/usage/usage.generated';
import UsageDisplay from './UsageDisplay';

const UsageContainer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data, loading, error } = useGetUsageQuery({
    variables: {
      input: {}, // This will fetch data for the entire subscription period
    },
  });

  return (
    <ErrorBoundary>
      <UsageDisplay data={data?.getUsage} error={error} isLoading={loading} isMobile={isMobile} />
    </ErrorBoundary>
  );
};

export default UsageContainer;
