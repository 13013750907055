export const TestIds = {
  // Layout & Navigation
  HEADER: 'header',
  MAIN_CONTENT: 'main-content',
  SIDEBAR: 'sidebar',

  // Controllers
  CONTROLLERS: {
    CONTAINER: 'controllers-container',
    LIST: 'controllers-list',
    ITEM: 'controller-item',
    EMPTY_STATE: 'controllers-empty-state',
    CREATE_BUTTON: 'create-controller-button',
  },

  // Forms
  UPLOAD_FORM: {
    CONTAINER: 'upload-form',
    VIDEO_UPLOAD: 'video-upload',
    NAME_INPUT: 'video-name-input',
    SUBMIT_BUTTON: 'upload-submit-button',
    USE_SELECTED_BUTTON: 'use-selected-button',
    EXISTING_VIDEO_SELECT: 'video-existing-video-select',
    ERROR_MESSAGE: 'upload-error-message',
  },

  // Video Upload Widget
  WIDEO_ASSETS: {
    CONTAINER: 'video-assets-list-view',
    LIST: 'video-assets-list',
    ITEM: 'video-asset-item',
    EMPTY_STATE: 'video-assets-empty-state',
    UPLOAD_BUTTON: 'upload-video-asset-button',
  },
  // Leads
  LEADS: {
    CONTAINER: 'leads-container',
    ITEM: 'leads-item',
    EMPTY_STATE: 'leads-empty-state',
  },
  // Analytics
  ANALYTICS: {
    CONTAINER: 'analytics-container',
    EMPTY_STATE: 'analytics-empty-state',
  },

  // Settings
  SETTINGS: {
    CONTAINER: 'settings-container',
    EMPTY_STATE: 'settings-empty-state',
  },

  // Profile
  PROFILE: {
    CONTAINER: 'profile-container',
    HISTORY_ITEM: 'profile-sub-history-item',
    EMPTY_STATE: 'profile-empty-state',
  },

  // Widget Configuration
  WIDGET_CONFIG: {
    SIZE_SELECT: 'widget-size-select',
    POSITION_SELECT: 'widget-position-select',
    COLOR_PICKER: 'widget-color-picker',
    PREVIEW: 'widget-preview',
  },

  // Controller Wizard
  CONTROLLER_WIZARD: {
    NEXT_STEP_BUTTON: 'controller-wizard-next-step-button',
  },
  // Embed Page
  EMBED_PAGE: {
    CONTAINER: 'embed-page-container',
  },

  // Common Components
  INPUTS: {
    TEXT: (name: string) => `input-${name}`,
    SELECT: (name: string) => `select-${name}`,
    CHECKBOX: (name: string) => `checkbox-${name}`,
    RADIO: (name: string) => `radio-${name}`,
  },

  // Dynamic IDs
  generateId: {
    controllerItem: (id: string) => `controller-${id}`,
    formField: (name: string) => `field-${name}`,
    button: (action: string) => `button-${action}`,
    modal: (name: string) => `modal-${name}`,
  },
} as const;
