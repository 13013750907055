// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/validation.ts

import * as yup from 'yup';

// export const configureSchema = yup.object().shape({
//   name: yup.string().required('Controller name is required'),
//   isAllTime: yup.boolean(),
//   scheduling: yup.object().shape({
//     validFrom: yup
//       .string()
//       .nullable()
//       .when('$isAllTime', {
//         is: false,
//         then: (schema) => schema.required('Start date is required'),
//       }),
//     validUntil: yup
//       .string()
//       .nullable()
//       .when('$isAllTime', {
//         is: false,
//         then: (schema) => schema.required('End date is required'),
//       }),
//   }),
//   buttonLabel: yup.string().required('Button label is required'),
//   disclaimerText: yup.string().required('Disclaimer text is required'),
// });

export const configureSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be at most 50 characters')
    .test('no-special-chars', 'Cannot contain special characters', (value) => {
      if (!value) return true; // Let required() handle empty
      return !/[^\w\s.,!?'"-]/g.test(value);
    })
    .test('no-html', 'Cannot contain HTML tags', (value) => {
      if (!value) return true;
      return !/<[^>]*>/g.test(value);
    })
    .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
      if (!value) return true;
      return value.trim().length > 0;
    }),
  action: yup.object().shape({
    buttonLabel: yup
      .string()
      .required('Button label is required')
      .min(3, 'Button label must be at least 3 characters')
      .max(50, 'Button label must be at most 50 characters')
      .test('no-special-chars', 'Cannot contain special characters', (value) => {
        if (!value) return true; // Let required() handle empty
        return !/[^\w\s.,!?'"-]/g.test(value);
      })
      .test('no-html', 'Cannot contain HTML tags', (value) => {
        if (!value) return true;
        return !/<[^>]*>/g.test(value);
      })
      .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
        if (!value) return true;
        return value.trim().length > 0;
      }),
    disclaimerText: yup
      .string()
      .required('Disclaimer text is required')
      .min(3, 'Disclaimer text must be at least 3 characters')
      .max(200, 'Disclaimer text must be at most 120 characters')
      .test('no-special-chars', 'Cannot contain special characters', (value) => {
        if (!value) return true; // Let required() handle empty
        return !/[^\w\s.,!?'"-]/g.test(value);
      })
      .test('no-html', 'Cannot contain HTML tags', (value) => {
        if (!value) return true;
        return !/<[^>]*>/g.test(value);
      })
      .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
        if (!value) return true;
        return value.trim().length > 0;
      }),
  }),
});

export interface ConfigureFormValues {
  name: string;
  action: {
    buttonLabel: string;
    disclaimerText: string;
  };
}
