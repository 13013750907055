import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps) => {
  const onRedirectCallback = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_CORE_API_AUDIENCE,
      }}
      cacheLocation="localstorage"
      clientId={clientId ?? ''}
      domain={domain ?? ''}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
