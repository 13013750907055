import * as yup from 'yup';
import { WidgetControllerFieldsEnum } from '../types';

export interface Stage {
  id: string;
  name?: string;
  nextStageId?: string;
  content: {
    id: string;
    type: 'video';
    videoAssetId: string;
    videoAssetName: string;
    videoAssetUrl: string;
    videoAssetThumbnailUrl?: string;
  };
  actions: {
    type: string;
    order: number;
    label: string;
    isFinal: boolean;
    closeWidget: boolean;
    redirect: {
      targetUrl: string;
      openNewTab: boolean;
    } | null;
    mailto: {
      email: string;
    } | null;
  }[];
}

export interface Layout {
  type: 'widget';
  widget: {
    design: {
      minified: {
        widgetSize: 'S' | 'M' | 'L';
        shape: 'square' | 'rectangle' | 'circle';
      };
    };
  };
}

export interface AddNewControllerValues {
  [WidgetControllerFieldsEnum.Name]: string;
  [WidgetControllerFieldsEnum.IsActive]: boolean;
  [WidgetControllerFieldsEnum.ValidFrom]?: string;
  [WidgetControllerFieldsEnum.ValidUntil]?: string;
  [WidgetControllerFieldsEnum.Url]?: string;
  [WidgetControllerFieldsEnum.InitialStageId]: string; // required?
  [WidgetControllerFieldsEnum.Stages]: Stage[];
  [WidgetControllerFieldsEnum.Layout]: Layout;
  [WidgetControllerFieldsEnum.VideoAssetsSelect]?: string;
}

export const stageSchema = () =>
  yup.object().shape({
    id: yup.string().required('Stage id is required'),
    name: yup.string().optional(),
    nextStageId: yup.string().optional(),
    content: yup
      .object()
      .shape({
        id: yup.string().required('Content id is required'),
        type: yup.string().oneOf(['video']).required('Content type is required'),
        videoAssetId: yup.string().required('Video asset id is required'),
        videoAssetName: yup.string().required('Video asset name is required'),
        videoAssetUrl: yup.string().required('Video asset URL is required'),
        videoAssetThumbnailUrl: yup.string().optional(),
      })
      .required('Content is required'),
    actions: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.string().required('Action type is required'),
          order: yup.number().required('Action order is required'),
          label: yup.string().required('Action label is required'),
          isFinal: yup.boolean().required('Is final action is required'),
          closeWidget: yup.boolean().required('Close widget field is required'),
          redirect: yup
            .object()
            .shape({
              targetUrl: yup.string().required('Redirect target URL is required'),
              openNewTab: yup.boolean().required('Open new tab field is required'),
            })
            .nullable()
            .optional(),
          mailto: yup
            .object()
            .shape({
              email: yup.string().required('Email is required'),
            })
            .nullable()
            .optional(),
        }),
      )
      .required('Actions are required'),
  });

export const layoutSchema = () =>
  yup.object().shape({
    type: yup.string().oneOf(['widget']).required('Layout type is required'),
    widget: yup
      .object()
      .shape({
        design: yup
          .object()
          .shape({
            minified: yup
              .object()
              .shape({
                widgetSize: yup.string().oneOf(['S', 'M', 'L']).required('Widget size is required'),
                shape: yup
                  .string()
                  .oneOf(['circle', 'rectangle', 'square'])
                  .required('Shape is required'),
              })
              .required('Minified design is required'),
          })
          .required('Design is required'),
      })
      .required('Widget configuration is required'),
  });

export const controllersSchema = (isAddMode: boolean) =>
  yup.object().shape({
    [WidgetControllerFieldsEnum.Name]: yup
      .string()
      .max(400, 'Controller name cannot be longer than 400 characters')
      .required('Controller name field is required'),
    [WidgetControllerFieldsEnum.IsActive]: yup
      .boolean()
      .default(true)
      .required('Active controller field is required'),
    [WidgetControllerFieldsEnum.Url]: yup
      .string()
      .max(100, 'Url cannot be longer than 200 characters')
      .optional(),
    [WidgetControllerFieldsEnum.ValidFrom]: yup.string().optional(),
    [WidgetControllerFieldsEnum.ValidUntil]: yup.string().optional(),
    [WidgetControllerFieldsEnum.InitialStageId]: yup
      .string()
      .required('Initial stage id field is required'),
    [WidgetControllerFieldsEnum.Stages]: yup
      .array<Stage>()
      .min(1, 'Missing video assests assigned to the controller')
      .required('Missing video assests assigned to the controller'),
    [WidgetControllerFieldsEnum.Layout]: layoutSchema(),
    [WidgetControllerFieldsEnum.VideoAssetsSelect]: yup.string(),
  });
