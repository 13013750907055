// src/components/Profile/components/PaymentSection.tsx

import { LoadingButton } from '@mui/lab';
import { Paper, Typography } from '@mui/material';
import { memo } from 'react';
import { PaymentSectionProps } from '../types';

export const PaymentSection = memo(({ onUpdatePayment }: PaymentSectionProps) => (
  <Paper elevation={3} sx={{ p: 3 }}>
    <Typography gutterBottom variant="h5">
      Payment Method
    </Typography>
    <Typography paragraph variant="body2">
      Update your payment information to ensure uninterrupted service.
    </Typography>
    <LoadingButton onClick={onUpdatePayment} variant="contained">
      Update Payment Method or view billing history
    </LoadingButton>
  </Paper>
));
