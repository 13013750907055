// hooks/useControllerActions.ts
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { useCustomerSettings } from '../contexts/CustomerSettingsContext';
import { useDeleteControllerMutation } from './api/deleteController/deleteController.generated';
import { ControllersDocument } from './api/controllers/controllers.generated';

export const useControllerActions = (onError: (message: string) => void) => {
  const navigate = useNavigate();
  const customerSettings = useCustomerSettings();
  const [deleteController] = useDeleteControllerMutation({
    refetchQueries: [{ query: ControllersDocument }],
  });

  const handleEdit = useCallback(
    (id: string) => {
      navigate(`/controllers/${id}`);
    },
    [navigate],
  );

  const handleRemove = useCallback(
    async (id: string) => {
      try {
        await deleteController({
          variables: { id },
        });
      } catch (err) {
        const message =
          'Failed to remove controller due to issues with the server. Please try again later or contact us.';
        onError(message);
        console.error('Delete failed:', (err as ApolloError).message);
      }
    },
    [deleteController, onError],
  );

  const handleCopyEmbed = useCallback(
    async (id: string) => {
      navigate(`/embed-instructions/${id}`);
    },
    [customerSettings?.customer?.clientId],
  );

  return {
    handleEdit,
    handleRemove,
    handleCopyEmbed,
  };
};
