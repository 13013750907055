// src/components/EditController/EditController.tsx

import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetControllerByIdQuery } from '../../hooks/api/controller/controller.generated';
import { LoadingSpinner } from '../Utils';
import { EditProvider } from './context/EditContext';
import { EditContent } from './components/EditContent';
import { useWizard, WizardProvider } from '../CreateNewControllerWizardV2/context/WizardContext';
import { mapControllerToDraft } from './utils/mapController';

const EditControllerContent: React.FC = () => {
  const { controllerId } = useParams<{ controllerId: string }>();
  const { data, loading, error, refetch } = useGetControllerByIdQuery({
    variables: { id: controllerId || '' },
    skip: !controllerId,
  });

  const { dispatch } = useWizard();

  useEffect(() => {
    if (controllerId) {
      refetch({ id: controllerId });
    }
  }, [controllerId, refetch]);

  useEffect(() => {
    if (data?.controller) {
      const draftController = mapControllerToDraft(data.controller);
      dispatch({ type: 'SET_ORIGINAL_CONTROLLER', payload: draftController });
    }
  }, [data, dispatch]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error: {error.message}</div>;
  if (!data?.controller) return <div>No controller found</div>;

  return (
    <Box sx={{ px: 3, py: 4, maxWidth: '1600px', mx: 'auto' }}>
      <EditProvider controller={data.controller}>
        <EditContent />
      </EditProvider>
    </Box>
  );
};

const EditController: React.FC = () => (
  <WizardProvider>
    <EditControllerContent />
  </WizardProvider>
);

export default EditController;
