import { Fragment, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  Stepper,
  Box,
  Step,
  StepButton,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionTypeButton } from './ActionTypeButton';
import { TextInput } from '../Inputs';
import { ActionData } from './ActionData.model';

const steps = ['Define type of action', 'Configure action', 'Style action button'];

const validationSchema = yup.object().shape({
  closeWidget: yup.boolean().default(false),
  isFinal: yup.boolean().required('Is final action is required'),
  order: yup.number().required('Order is required'),
  type: yup
    .string()
    .oneOf(['redirect', 'mailto', 'phonecall', 'emailCollection'])
    .required('Action type is required'),
  label: yup.string().required('Label is required'),
  redirect: yup.mixed().test('redirect', 'Redirect is required', (value, context) => {
    const { parent } = context;
    const { type } = parent;
    if (type && type === 'redirect') {
      return value && (value as any).targetUrl && typeof (value as any).openNewTab === 'boolean';
    }
    return true;
  }),
  mailto: yup.mixed().test('mailto', 'Email is required', (value, context) => {
    const { parent } = context;
    const { type } = parent;
    if (type === 'mailto') {
      return value && (value as any).email;
    }
    return true;
  }),
  emailCollection: yup.object().shape({
    topText: yup.string(),
    instructionsText: yup.string().required('Instructions text is required'),
    disclaimerText: yup.string(),
    disclaimerUrl: yup.string().url('Invalid URL'),
  }),
});

export const AddActionsWizardComponent = ({
  onSave,
  onClose,
}: {
  onSave: (data: ActionData) => void;
  onClose: () => void;
}) => {
  const methods = useForm({
    resolver: yupResolver<ActionData>(validationSchema),
    defaultValues: {
      order: 0,
      label: '',
      isFinal: true,
      emailCollection: {
        instructionsText: 'Please enter your email address',
        disclaimerText: 'By clicking the "Send" button, you agree to our terms and conditions',
      },
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const submitRef = useRef<HTMLFormElement>(null);

  const forwardSave = (data: ActionData) => {
    // TODO default fields for other types are always copied, even if they are not used
    console.info('Submit ModalForm', data);
    onSave(data);
    onClose();
  };

  const handleSubmitWithoutPropagation = (e: any) => {
    console.info('handleSubmitWithoutPropagation', e);
    console.info('handleSubmitWithoutPropagation', e.target);
    console.info('errors', errors);
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(forwardSave, (err) => console.log(err))(e);
  };

  const type = watch('type');

  //    stepper settings
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    //  It's the last step, but not all steps have been completed,
    //  find the first step that has been completed
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    if (submitRef && submitRef.current) {
      submitRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmitWithoutPropagation} ref={submitRef}>
            {allStepsCompleted() ? (
              // effectively the last step, not reachable as should close modal on complete
              <Fragment>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>{JSON.stringify(actionData, null, 2)}</Typography> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <Typography sx={{ mt: 2, mb: 1, py: 1 }}> </Typography>
                {activeStep === 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <ActionTypeButton
                      label="Redirect"
                      description="Redirect viewer to a different subpage or external url"
                      onClick={() => {
                        console.log('Redirect');
                        handleComplete();
                      }}
                      registerFieldName="type"
                      registerFieldValue="redirect"
                    />
                    <ActionTypeButton
                      label="Mail to"
                      description="Allows viewer to automatically open their email client with predefined email address"
                      onClick={() => {
                        handleComplete();
                      }}
                      registerFieldName="type"
                      registerFieldValue="mailto"
                    />
                    <ActionTypeButton
                      label="Phone call"
                      description="Allows viewer to automatically open their phone client with predefined phone number"
                      onClick={() => console.log('Show message')}
                      registerFieldName="type"
                      registerFieldValue="phonecall"
                      disabled
                    />
                    <ActionTypeButton
                      label="Email collection"
                      description="Allows you to collect viewers email addresses."
                      onClick={() => {
                        handleComplete();
                      }}
                      registerFieldName="type"
                      registerFieldValue="emailCollection"
                    />
                  </Box>
                )}
                {/* emailCollection only */}
                {activeStep === 1 && type === 'emailCollection' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                      {...register('emailCollection.instructionsText')}
                      error={!!errors.emailCollection?.instructionsText}
                      helperText={errors.emailCollection?.instructionsText?.message}
                      label="Instructions text"
                    />
                    <TextField
                      {...register('emailCollection.disclaimerText')}
                      error={!!errors.emailCollection?.disclaimerText}
                      helperText={errors.emailCollection?.disclaimerText?.message}
                      label="Disclaimer"
                    />
                    <TextField
                      {...register('emailCollection.disclaimerUrl')}
                      error={!!errors.emailCollection?.disclaimerUrl}
                      helperText={errors.emailCollection?.disclaimerUrl?.message}
                      label="Disclaimer link"
                    />
                  </Box>
                )}
                {/* mailto only */}
                {activeStep === 1 && type === 'mailto' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <TextField
                      {...register('mailto.email')}
                      error={!!errors.mailto?.email}
                      helperText={errors.mailto?.email?.message}
                      label="Email"
                    />
                  </Box>
                )}
                {/* redirect only */}
                {activeStep === 1 && type === 'redirect' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <>
                      <TextField
                        {...register('redirect.targetUrl')}
                        error={!!errors.redirect?.targetUrl}
                        helperText={errors.redirect?.targetUrl?.message}
                        label="Target URL"
                      />
                      <FormControlLabel
                        control={<Switch {...register('redirect.openNewTab')} />}
                        label="Open in new tab"
                      />
                    </>
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <TextInput
                      {...register('label')}
                      errors={errors}
                      label="Label for action button"
                      onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                        setValue('label' as 'label', value.target.value)
                      }
                    />
                  </Box>
                )}
                {/* don't show buttons on first step */}
                {activeStep !== 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mb: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography variant="caption" sx={{ display: 'inline-block' }}>
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <Button onClick={handleComplete}>
                          {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                        </Button>
                      ))}
                  </Box>
                )}
              </Fragment>
            )}
          </form>
        </FormProvider>
      </div>
    </Box>
  );
};
