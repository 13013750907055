import { memo } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { UsagePieChartProps } from '../types';

export const UsagePieChart = memo(({ data, colors }: UsagePieChartProps) => (
  <ResponsiveContainer>
    <PieChart>
      <Pie
        cx="50%"
        cy="50%"
        data={data}
        dataKey="value"
        fill="#8884d8"
        innerRadius={60}
        outerRadius={80}
        paddingAngle={5}
      >
        {data.map((_, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  </ResponsiveContainer>
));
