import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#441078',
    },
    secondary: {
      main: '#653FFE',
    },
    background: {
      default: '#FDFDFD',
    },
  },
  typography: {
    fontFamily: 'Circe',
    subtitle1: {
      color: '#222222',
    },
    subtitle2: {
      color: '#FFF',
      fontSize: '20px',
      fontWeight: 'normal',
    },
  },
});
