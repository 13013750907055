// src/components/CreateNewControllerWizardV2/steps/StyleStep/components/PositionConfig.tsx

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useWizard } from '../../../context/WizardContext';
import { useStyleUpdates } from '../hooks/useStyleUpdates';

const max = 9999;

interface PositionConfigProps {
  onStyleChange: (changes: any) => void;
}

export const PositionConfig: React.FC<PositionConfigProps> = ({ onStyleChange }) => {
  const { state } = useWizard();
  const { positioning } = state.styleConfig;
  const { updateStyle } = useStyleUpdates();

  const handlePositionChange = (position: 'bottomLeft' | 'bottomRight') => {
    onStyleChange({
      positioning: { ...positioning, position },
    });
  };

  const handleMarginChange = (
    field: 'marginBottom' | 'marginLeft' | 'marginRight',
    value: number,
  ) => {
    const positionKey = positioning.position as 'bottomLeft' | 'bottomRight';
    onStyleChange({
      positioning: {
        ...positioning,
        [positionKey]: {
          ...(positioning[positionKey] || {}),
          [field]: value,
        },
      },
    });
  };

  return (
    <Box>
      <Typography gutterBottom variant="subtitle1">
        Position
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Widget Position</InputLabel>
        <Select
          label="Widget Position"
          onChange={(e) => handlePositionChange(e.target.value as 'bottomLeft' | 'bottomRight')}
          value={positioning.position}
        >
          <MenuItem value="bottomLeft">Bottom Left</MenuItem>
          <MenuItem value="bottomRight">Bottom Right</MenuItem>
        </Select>
      </FormControl>

      <Box mt={2}>
        <TextField
          fullWidth
          InputProps={{ inputProps: { min: 0, max } }}
          label="Margin Bottom"
          onChange={(e) =>
            handleMarginChange('marginBottom', Math.min(Number(e.target.value), max))
          }
          sx={{ mb: 2 }}
          type="number"
          value={positioning[positioning.position]?.marginBottom ?? 0}
        />

        {positioning.position === 'bottomLeft' ? (
          <TextField
            fullWidth
            InputProps={{ inputProps: { min: 0, max } }}
            label="Margin Left"
            onChange={(e) =>
              handleMarginChange('marginLeft', Math.min(Number(e.target.value), max))
            }
            type="number"
            value={positioning.bottomLeft?.marginLeft ?? 0}
          />
        ) : (
          <TextField
            fullWidth
            InputProps={{ inputProps: { min: 0, max } }}
            label="Margin Right"
            onChange={(e) =>
              handleMarginChange('marginRight', Math.min(Number(e.target.value), max))
            }
            type="number"
            value={positioning.bottomRight?.marginRight ?? 0}
          />
        )}
      </Box>
    </Box>
  );
};
