import React, { FC, useEffect, useMemo } from 'react';
import {
  Alert,
  Box,
  Paper,
  Snackbar,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import BackButton from '../Common/BackButton';
import { VideoAssetFieldsEnum } from '../types';
import { LoadingSpinner } from '../Utils';
import { useGetAssetQuery } from '../../hooks/api/asset/asset.generated';
import { TestIds } from '../../test-utils';
import { useAssetForm } from '../../hooks/useAssetForm';
import AssetForm from './AssetForm';

const AddNewAssetView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme<Theme>();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    isSubmitting,
    error,
    setError,
    onSubmit,
  } = useAssetForm(id);

  const { data: assetData, loading: assetLoading } = useGetAssetQuery({
    variables: { id: id || '' },
    skip: !id,
  });

  // Use memo for computing if we're in edit mode
  const isEditMode = useMemo(() => !!id, [id]);

  // Effect for setting initial form data
  useEffect(() => {
    if (isEditMode && assetData?.asset) {
      reset({
        [VideoAssetFieldsEnum.Name]: assetData.asset.name,
        [VideoAssetFieldsEnum.VideoAsset]: assetData.asset.videoAsset,
      });
    }
  }, [isEditMode, assetData, reset]);

  if (isEditMode && assetLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      data-testId={TestIds.UPLOAD_FORM.CONTAINER}
      sx={{
        width: '100%',
        maxWidth: '1200px', // Add a max-width for very large screens
        mx: 'auto', // Center the content
        px: { xs: 2, sm: 3, md: 4 }, // Responsive padding
      }}
    >
      <BackButton label="Back to videos" path="/assets" />

      <Typography
        variant="h4"
        sx={{
          fontSize: 36,
          fontWeight: 700,
          pb: 6,
          pt: 4,
        }}
      >
        {isEditMode ? 'Edit video' : 'Add a new video to your library'}
      </Typography>

      <Paper
        sx={{
          p: isLargeScreen ? 4 : 2,
          width: '100%', // Use full width
        }}
      >
        <AssetForm
          isEditMode={isEditMode}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={errors}
          setValue={setValue}
        />
      </Paper>

      <Snackbar autoHideDuration={5000} onClose={() => setError(null)} open={!!error}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  );
};

export default AddNewAssetView;
