import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const FileRequirements = () => {
  return (
    <Box padding="5%">
      <Typography variant="body1" gutterBottom>
        Please ensure that your video files meet the following requirements before uploading:
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Requirement</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Accepted File Types</TableCell>
              <TableCell>.mp4, .mov, .wmv, .avi</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum File Size</TableCell>
              <TableCell>15 MB</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum Video Duration</TableCell>
              <TableCell>30 seconds</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Aspect Ratio</TableCell>
              <TableCell>Between 0.5 and 0.75 (vertical rectangle)</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FileRequirements;
