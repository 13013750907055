// src/components/Usage/components/DailyGraph.tsx
import React, { memo } from 'react';
import { Box } from '@mui/material';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { format, parseISO } from 'date-fns';
import { DailyGraphProps } from '../types';

export const DailyGraph = memo(({ usageData, isMobile }: DailyGraphProps) => {
  const chartHeight = 400;

  if (isMobile) {
    return (
      <Box sx={{ width: '100%', height: chartHeight, mb: 4 }}>
        <ResponsiveContainer height={chartHeight} width="100%">
          <BarChart
            data={usageData}
            layout="vertical"
            margin={{ top: 20, right: 30, left: 50, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              dataKey="date"
              tickFormatter={(tick) => format(parseISO(tick), 'MM/dd')}
              type="category"
              width={80}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="plays" fill="#8884d8" />
            <Bar dataKey="views" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', height: chartHeight, mb: 4 }}>
      <ResponsiveContainer height={chartHeight} width="100%">
        <BarChart data={usageData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={(tick) => format(parseISO(tick), 'MM/dd')} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="plays" fill="#8884d8" />
          <Bar dataKey="views" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
});
