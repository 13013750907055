import { Box, FormControl, FormHelperText, styled, TextField, Typography } from '@mui/material';
import React, { ChangeEventHandler } from 'react';

interface CustomLabelInputProps {
  label: string;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  errors?: any;
  name?: string;
  'data-testid'?: string;
  inputProps?: any;
  fullWidth?: boolean;
}

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
});

const TextInput = React.forwardRef<HTMLInputElement, CustomLabelInputProps>(
  (
    {
      label,
      disabled,
      value,
      onChange,
      placeholder,
      errors,
      name,
      'data-testid': dataTestId,
      inputProps,
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const getNestedError = (errorsss: any, path: string) => {
      return path.split('.').reduce((error, key) => error?.[key], errorsss);
    };

    const fieldError = name ? getNestedError(errors, name) : undefined;
    return (
      <Box>
        <FormControl fullWidth>
          <Typography pb={1}>{label}</Typography>
          <StyledTextField
            {...props}
            disabled={disabled}
            error={!!fieldError}
            fullWidth={fullWidth}
            InputProps={{
              style: {
                backgroundColor: disabled ? '#F4F4F4' : 'white',
              },
              inputProps: {
                'data-testid': dataTestId,
                ...inputProps,
              },
            }}
            inputRef={ref}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            variant="outlined"
          />
        </FormControl>
        {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
      </Box>
    );
  },
);

export default TextInput;
