import { ButtonBase, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { BackArrowIcon } from '../../assets/icons';

interface BackButtonProps {
  path: string;
  label: string;
}

const BackButton: FC<BackButtonProps> = ({ label, path }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(path);
  };
  return (
    <ButtonBase
      onClick={handleButtonClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        cursor: 'pointer',
      }}
    >
      <BackArrowIcon />
      <Typography color="secondary" fontSize={24} mt="3px">
        {label}
      </Typography>
    </ButtonBase>
  );
};

export default BackButton;
