import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type GetUsageQueryVariables = Types.Exact<{
  input: Types.GetUsageInput;
}>;

export type GetUsageQuery = {
  __typename?: 'Query';
  getUsage: {
    __typename?: 'UsageData';
    totalPlaysAllowed: number;
    totalPlaysUsed: number;
    totalViewsAllowed: number;
    totalViewsUsed: number;
    dailyUsage: Array<{ __typename?: 'DailyUsage'; date: string; plays: number; views: number }>;
    currentPlan: { __typename?: 'CurrentPlan'; name: string; startDate: string; endDate: string };
  };
};

export const GetUsageDocument = gql`
  query GetUsage($input: GetUsageInput!) {
    getUsage(input: $input) {
      dailyUsage {
        date
        plays
        views
      }
      totalPlaysAllowed
      totalPlaysUsed
      totalViewsAllowed
      totalViewsUsed
      currentPlan {
        name
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useGetUsageQuery__
 *
 * To run a query within a React component, call `useGetUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsageQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsageQuery, GetUsageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsageQuery, GetUsageQueryVariables>(GetUsageDocument, options);
}
export function useGetUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsageQuery, GetUsageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsageQuery, GetUsageQueryVariables>(GetUsageDocument, options);
}
export type GetUsageQueryHookResult = ReturnType<typeof useGetUsageQuery>;
export type GetUsageLazyQueryHookResult = ReturnType<typeof useGetUsageLazyQuery>;
export type GetUsageQueryResult = Apollo.QueryResult<GetUsageQuery, GetUsageQueryVariables>;
export function refetchGetUsageQuery(variables: GetUsageQueryVariables) {
  return { query: GetUsageDocument, variables };
}
