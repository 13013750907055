// src/components/ViewerData/ViewerDataDisplay.tsx

import React, { memo } from 'react';
import { Box, Typography, TablePagination } from '@mui/material';
import { ViewerDataDisplayProps } from './types';
import ViewerDataToolbar from './components/ViewerDataToolbar';
import DataFilters from './components/DataFilters';
import ViewerDataTable from './components/ViewerDataTable';
import { LoadingSpinner } from '../Utils';

const ViewerDataDisplay = memo(
  ({
    data,
    controllersData,
    loading,
    selectedControllerId,
    onControllerChange,
    onExport,
    pagination,
  }: ViewerDataDisplayProps) => {
    const hasNoControllers = controllersData?.controllers.controllers.length === 0;

    if (loading && !data) {
      return <LoadingSpinner />;
    }

    return (
      <Box sx={{ width: '100%', mb: 2 }}>
        <ViewerDataToolbar hasNoControllers={hasNoControllers} />

        {hasNoControllers ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              py: 4,
            }}
          >
            <Typography>No leads as you have no controllers yet. Add controller first.</Typography>
          </Box>
        ) : (
          <>
            <DataFilters
              controllersData={controllersData}
              onControllerChange={onControllerChange}
              onExport={onExport}
              selectedControllerId={selectedControllerId}
            />

            {data && (
              <>
                <ViewerDataTable data={data} isLoading={loading} />
                <TablePagination
                  component="div"
                  count={pagination.total}
                  onPageChange={pagination.onPageChange}
                  onRowsPerPageChange={pagination.onRowsPerPageChange}
                  page={pagination.page}
                  rowsPerPage={pagination.rowsPerPage}
                  sx={{
                    '.MuiTablePagination-toolbar': {
                      flexWrap: 'wrap',
                    },
                  }}
                />
              </>
            )}
          </>
        )}
      </Box>
    );
  },
);

export default ViewerDataDisplay;
