// src/components/ViewerData/components/ViewerDataToolbar.tsx

import React, { memo } from 'react';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import TableViewToolbar from '../../Common/TableViewToolbar';

const ViewerDataToolbar = memo(({ hasNoControllers }: { hasNoControllers: boolean }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        pb: { xs: 5, md: 2.5 },
        gap: 2,
        width: '100%',
      }}
    >
      <TableViewToolbar title="Leads" />
      {hasNoControllers && (
        <Button
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to="/new-controller-wizard"
          sx={{
            px: { xs: 2.5, md: 11.25 },
            py: 1.5,
            textTransform: 'none',
            alignSelf: { xs: 'stretch', md: 'flex-start' },
          }}
        >
          New controller
        </Button>
      )}
    </Box>
  );
});

export default ViewerDataToolbar;
