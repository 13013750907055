// src/components/Profile/components/UserAvatar.tsx

import { memo } from 'react';
import { Avatar, Typography, Chip, Box } from '@mui/material';
import { format } from 'date-fns';
import { UserAvatarProps } from '../types';

export const UserAvatar = memo(({ email, status, cancellationDate }: UserAvatarProps) => (
  <Box alignItems="center" display="flex" flexDirection="column" mb={2}>
    <Avatar sx={{ width: 100, height: 100, mb: 2 }}>{email[0].toUpperCase()}</Avatar>
    <Typography align="center" sx={{ wordBreak: 'break-word' }} variant="h6">
      {email}
    </Typography>
    <Chip color={status === 'active' ? 'success' : 'default'} label={status} sx={{ mt: 1 }} />
    {cancellationDate && (
      <Chip
        color="warning"
        label={`Cancels at ${format(new Date(cancellationDate), 'PPP')}`}
        sx={{ mt: 1 }}
      />
    )}
  </Box>
));
