import React, { useCallback } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useControllersQuery } from '../../hooks/api/controllers/controllers.generated';
import ErrorBoundary from '../Utils/ErrorBoundry';
import DateRangePicker from '../Utils/DateRangePicker';
import ControllerSelect from '../Utils/ControllerSelect';
import AnalyticsData from './AnalyticsData';

const AnalyticsView: React.FC = () => {
  const { data: controllersData, error: controllersError } = useControllersQuery({});
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedControllerId,
    setSelectedControllerId,
    data,
    loading,
    error,
    handleDateChange,
  } = useAnalytics();

  const handleError = useCallback((passedError: Error, errorInfo: React.ErrorInfo) => {
    // You can log the error to your error reporting service here
    console.error('Error in AnalyticsView:', passedError, errorInfo);

    // Determine if this is a critical error
    if (passedError.message.includes('Critical')) {
      (passedError as any).isCritical = true;
    }
  }, []);

  if (controllersError) {
    throw new Error(`Error loading controllers: ${controllersError.message}`);
  }

  return (
    <ErrorBoundary
      fallback={
        <Container maxWidth="md">
          <Typography variant="h4" color="error" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          <Typography>
            We&apos;re having trouble loading the Analytics Dashboard. Please try again later or
            contact support if the problem persists.
          </Typography>
        </Container>
      }
      onError={handleError}
    >
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Analytics Dashboard
        </Typography>
        <Box mb={2}>
          <ControllerSelect
            selectedControllerId={selectedControllerId}
            controllersData={controllersData!}
            setSelectedControllerId={setSelectedControllerId}
          />
        </Box>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          onUpdate={handleDateChange}
        />
        <Box mt={4}>
          <AnalyticsData data={data?.getAnalytics} loading={loading} error={error} />
        </Box>
      </Container>
    </ErrorBoundary>
  );
};

export default AnalyticsView;
