export enum VideoAssetFieldsEnum {
  Name = 'name',
  VideoAsset = 'videoAsset',
}

export enum WidgetControllerFieldsEnum {
  Name = 'name',
  ValidFrom = 'validFrom',
  ValidUntil = 'validUntil',
  IsActive = 'isActive',
  Url = 'url',
  // FullAsset = 'fullAsset',
  InitialStageId = 'initialStageId',
  Layout = 'layout',
  Stages = 'stages',
  VideoAssetsSelect = 'videoAssetsSelect',
}

export function removeTypenames(value: any): any {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(removeTypenames);
  }
  if (typeof value === 'object') {
    const newObj: any = {};
    for (const [key, subValue] of Object.entries(value)) {
      if (key !== '__typename') {
        newObj[key] = removeTypenames(subValue);
      }
    }
    return newObj;
  }
  return value;
}
