import * as yup from 'yup';
import { VideoAssetFieldsEnum } from '../types';

export const videoAssetsSchema = () =>
  yup.object().shape({
    [VideoAssetFieldsEnum.Name]: yup
      .string()
      .max(400, 'Video name cannot be longer than 400 characters')
      .required('Video name field is required'),
    [VideoAssetFieldsEnum.VideoAsset]: yup
      .object()
      .shape({
        fileId: yup.string().required('Video fileId field is required'),
        name: yup.string().required('Video name field is required'),
      })
      .required('Video field is required'),
  });
