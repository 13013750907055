// src/components/Common/EmbedStep/components/SupportSection.tsx

import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { SupportBox } from '../styles';
import { appConfig } from '../../../app-config';

const SupportSection = memo(() => {
  return (
    <SupportBox>
      <div className="support-container">
        <Typography sx={{ mb: 1 }} variant="body2">
          Need help? Contact our support:
        </Typography>
        <Typography
          sx={{
            color: '#441078',
            fontWeight: '500',
            textTransform: 'uppercase',
          }}
          variant="body1"
        >
          <a
            href={`mailto:${appConfig.supportEmail}`}
            style={{ color: '#441078', textDecoration: 'none' }}
          >
            {appConfig.supportEmail}
          </a>
        </Typography>
      </div>
    </SupportBox>
  );
});

export default SupportSection;
