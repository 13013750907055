import React, { useEffect, useState } from 'react';
import { Alert, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Login: React.FC = () => {
  const { isAuthenticated, isLoading, loginAndRedirectBack } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const messageParam = urlParams.get('message');
    const successParam = urlParams.get('success');

    if (messageParam) {
      if (successParam === 'false') {
        setError(messageParam);
      } else if (successParam === 'true') {
        setSuccess(messageParam);
      }
    }

    if (!isLoading && !isAuthenticated) {
      const returnUrlParam = urlParams.get('returnUrl');
      const returnUrl = returnUrlParam ? decodeURIComponent(returnUrlParam) : null;

      loginAndRedirectBack(returnUrl ?? window.location.origin);
    }
  }, [isLoading, isAuthenticated, loginAndRedirectBack]);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Container
      maxWidth="sm"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      <div style={{ textAlign: 'center' }}>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {success && (
          <Button variant="contained" onClick={handleGoBack}>
            Go back to Customer Panel
          </Button>
        )}
      </div>
    </Container>
  );
};

export default Login;
