// src/components/EditController/components/VideoReplaceSection.tsx

import React, { useState } from 'react';
import { Box, Paper, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useEditContext } from '../context/EditContext';
import { useAssetsQuery } from '../../../hooks/api/assets/assets.generated';
import { VideoSelection } from '../../Common/VideoComponents/VideoSelection';
import { VideoUploadForm } from '../../Common/VideoComponents/VideoUploadForm';

interface CurrentVideoDisplayProps {
  name: string;
  duration?: number;
  resolution?: string;
}

const CurrentVideoDisplay: React.FC<CurrentVideoDisplayProps> = ({
  name,
  duration,
  resolution,
}) => (
  <Box sx={{ mb: 3 }}>
    <Typography color="text.secondary" gutterBottom variant="subtitle2">
      Current Video
    </Typography>
    <Typography gutterBottom variant="h6">
      {name}
    </Typography>
    <Box sx={{ display: 'flex', gap: 2 }}>
      {duration && (
        <Typography color="text.secondary" variant="body2">
          Duration: {Math.round(duration)}s
        </Typography>
      )}
      {resolution && (
        <Typography color="text.secondary" variant="body2">
          Resolution: {resolution}
        </Typography>
      )}
    </Box>
  </Box>
);

export const VideoReplaceSection: React.FC = () => {
  const { state, dispatch } = useEditContext();
  const { originalController, pendingChanges, isSaving } = state;
  const [videoChangeMode, setVideoChangeMode] = useState<'current' | 'upload' | 'existing'>(
    'current',
  );
  const { data: assetsData } = useAssetsQuery();

  // Get current video info from either pending changes or original controller
  const currentVideo = React.useMemo(() => {
    if (pendingChanges.videoAsset) {
      return pendingChanges.videoAsset;
    }
    return {
      fileId: originalController?.stages[0].content.videoAssetId,
      name: originalController?.stages[0].content.videoAssetName,
    };
  }, [pendingChanges.videoAsset, originalController]);

  // Find the asset ID for the current video
  const currentAssetId = React.useMemo(() => {
    if (!assetsData?.assets.assets) return '';
    return (
      assetsData.assets.assets.find((asset) => asset.videoAsset.fileId === currentVideo.fileId)
        ?.id || ''
    );
  }, [assetsData, currentVideo]);

  const handleUploadComplete = async (videoData: { fileId: string; name: string }) => {
    dispatch({
      type: 'UPDATE_VIDEO',
      payload: videoData,
    });
  };

  const handleVideoSelect = async (assetId: string) => {
    const selectedAsset = assetsData?.assets.assets.find((asset) => asset.id === assetId);
    if (selectedAsset) {
      // Only update if a different video is selected
      if (selectedAsset.videoAsset.fileId !== currentVideo.fileId) {
        dispatch({
          type: 'UPDATE_VIDEO',
          payload: {
            fileId: selectedAsset.videoAsset.fileId,
            name: selectedAsset.name,
          },
        });
      } else {
        // If same video selected, clear pending changes
        dispatch({
          type: 'UPDATE_VIDEO',
          payload: null,
        });
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography gutterBottom variant="h6">
        Video Configuration
      </Typography>

      {state.originalController?.stages[0].content.videoAssetName && (
        <CurrentVideoDisplay name={state.originalController?.stages[0].content.videoAssetName} />
      )}

      <Box sx={{ mb: 3 }}>
        <Typography color="text.secondary" gutterBottom variant="subtitle2">
          Video Options (Wideo replacement functionality is coming soon...)
        </Typography>
        <ToggleButtonGroup
          disabled
          exclusive
          onChange={(_, newMode) => {
            if (newMode !== null) {
              setVideoChangeMode(newMode);
              if (newMode === 'current') {
                // Clear any pending changes when switching back to current
                dispatch({
                  type: 'UPDATE_VIDEO',
                  payload: null,
                });
              }
            }
          }}
          sx={{ mb: 2 }}
          value={videoChangeMode}
        >
          <ToggleButton value="current">Keep Current</ToggleButton>
          <ToggleButton value="upload">Upload New</ToggleButton>
          <ToggleButton value="existing">Choose Existing</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {videoChangeMode === 'upload' && (
        <Box sx={{ mt: 3 }}>
          <Typography color="text.secondary" gutterBottom variant="subtitle2">
            Upload New Video
          </Typography>
          <VideoUploadForm disabled={isSaving} onUploadComplete={handleUploadComplete} />
        </Box>
      )}

      {videoChangeMode === 'existing' && (
        <Box sx={{ mt: 3 }}>
          <Typography color="text.secondary" gutterBottom variant="subtitle2">
            Select Existing Video
          </Typography>
          <VideoSelection
            disabled={isSaving}
            initialSelectedId={currentAssetId}
            onSelect={handleVideoSelect}
          />
        </Box>
      )}
    </Paper>
  );
};
