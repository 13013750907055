// hooks/useAssetActions.ts
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteAssetMutation } from './api/deleteAsset/deleteAsset.generated';

export const useAssetActions = (onError: (message: string) => void) => {
  const navigate = useNavigate();
  const [deleteAsset] = useDeleteAssetMutation();

  const handleEdit = useCallback(
    (id: string) => {
      navigate(`/assets/${id}`);
    },
    [navigate],
  );

  const handleRemove = useCallback(
    async (id: string) => {
      try {
        await deleteAsset({ variables: { id } });
      } catch (err) {
        onError('Failed to delete asset');
        console.error('Delete failed:', err);
      }
    },
    [deleteAsset, onError],
  );

  const handleAddNew = useCallback(() => {
    navigate('/assets/add-new-asset');
  }, [navigate]);

  return {
    handleEdit,
    handleRemove,
    handleAddNew,
  };
};
