// src/components/CreateNewControllerWizardV2/hooks/useWizardNavigation.ts

import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useWizard } from '../context/WizardContext';

export const useWizardNavigation = () => {
  const { state, dispatch } = useWizard();
  const navigate = useNavigate();

  const goToNextStep = useCallback(() => {
    if (state.currentStep === 3) {
      navigate('/controllers');
      return;
    }

    dispatch({ type: 'SET_STEP', payload: state.currentStep + 1 });
  }, [state.currentStep, dispatch]);

  const goToPreviousStep = useCallback(() => {
    dispatch({ type: 'SET_STEP', payload: state.currentStep - 1 });
  }, [state.currentStep, dispatch]);

  const goToStep = useCallback(
    (step: number) => {
      dispatch({ type: 'SET_STEP', payload: step });
    },
    [dispatch],
  );

  return {
    currentStep: state.currentStep,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    canGoBack: state.currentStep > 0 && state.currentStep < 3,
    canGoForward: state.currentStep < 3,
  };
};
