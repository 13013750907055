// src/components/ViewerData/ViewerDataContainer.tsx

import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { LoadingSpinner } from '../Utils';
import { useGetViewerDataQuery } from '../../hooks/api/viewerData/viewerData.generated';
import { useControllersQuery } from '../../hooks/api/controllers/controllers.generated';
import { usePagination } from '../../hooks';
import { useExcel } from '../../hooks/api/useExcel';
import ErrorBoundary from '../Utils/ErrorBoundry';
import ViewerDataDisplay from './ViewerDataDisplay';

const ViewerDataContainer = () => {
  // Pagination hook
  const { order, orderBy, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    usePagination();

  // State
  const [selectedControllerId, setSelectedControllerId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  // Excel export hook
  const { exportToXlsx } = useExcel();

  // Memoize query variables
  const queryVariables = useMemo(
    () => ({
      page,
      limit: rowsPerPage,
      sortBy: orderBy,
      sortOrder: order,
      controllerId: selectedControllerId || undefined,
    }),
    [page, rowsPerPage, orderBy, order, selectedControllerId],
  );

  // Data fetching
  const {
    data,
    loading,
    error: queryError,
  } = useGetViewerDataQuery({
    variables: queryVariables,
  });

  const { data: controllersData, loading: controllersLoading } = useControllersQuery();

  // Callbacks
  const handleExportClick = useCallback(() => {
    if (!data?.viewerData?.data) return;

    exportToXlsx({
      fileName: `leads-report-${new Date().toISOString()}`,
      sheets: [
        {
          name: 'Leads',
          headers: [
            // 'Submission Type',
            'Email',
            // 'Phone',
            // 'Text',
            // 'Status',
            'Created At',
            'ControllerId',
            // 'StageId',
          ],
          data: data.viewerData.data.map((item) => [
            // item.submissionType,
            item.data.email || '',
            // item.data.phone || '',
            // item.data.text || '',
            // item.status,
            new Date(item.createdAt).toLocaleString(),
            item.controllerId,
            // item.stageId,
          ]),
        },
      ],
    });
  }, [data, exportToXlsx]);

  const handleControllerChange = useCallback((controllerId: string) => {
    setSelectedControllerId(controllerId);
  }, []);

  if (controllersLoading && !controllersData) {
    return <LoadingSpinner />;
  }

  if (!controllersLoading && !controllersData) {
    return <p>No leads yet.</p>;
  }

  return (
    <ErrorBoundary>
      <ViewerDataDisplay
        controllersData={controllersData!}
        data={data}
        loading={loading}
        onControllerChange={handleControllerChange}
        onExport={handleExportClick}
        pagination={{
          page,
          rowsPerPage,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
          total: data?.viewerData.meta?.total || 0,
        }}
        selectedControllerId={selectedControllerId}
      />

      <Snackbar
        autoHideDuration={6000}
        onClose={() => setError(null)}
        open={!!error || !!queryError}
      >
        <Alert severity="error">{error || queryError?.message}</Alert>
      </Snackbar>
    </ErrorBoundary>
  );
};

export default ViewerDataContainer;
