// components/Assets/AssetForm.tsx
import React, { FC } from 'react';
import { useTheme, useMediaQuery, Button, Grid, Box } from '@mui/material';
import { Asset } from './AssetCard';
import { TextInput } from '../Inputs';
import { VideoAssetFieldsEnum } from '../types';
import DragAndDropInput from '../Common/DragAndDropInput';
import { UploadedMovieFileInputType } from '../../interfaces';

export interface AssetFormValues {
  [VideoAssetFieldsEnum.Name]: string;
  [VideoAssetFieldsEnum.VideoAsset]: UploadedMovieFileInputType;
}

interface AssetFormProps {
  initialData?: Asset;
  isEditMode: boolean;
  isSubmitting: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  register: any; // Type this properly based on your form
  errors: any;
  setValue: any;
}

const AssetForm: FC<AssetFormProps> = ({
  isEditMode,
  isSubmitting,
  onSubmit,
  register,
  errors,
  setValue,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item lg={12} sm={12} xs={12}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextInput
              {...register(VideoAssetFieldsEnum.Name)}
              errors={errors}
              label="Your video will be visible in library with that name"
              onChange={(e) => setValue(VideoAssetFieldsEnum.Name, e.target.value)}
            />
            <DragAndDropInput
              {...register(VideoAssetFieldsEnum.VideoAsset)}
              errors={errors}
              label="Add video file"
              onFileUploadComplete={(value) => {
                setValue(VideoAssetFieldsEnum.VideoAsset, value);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" pt={6} width="100%">
        <Button
          color="secondary"
          disabled={isSubmitting}
          sx={{
            textTransform: 'none',
            px: 9.5,
            py: 1.6,
            fontWeight: 700,
          }}
          type="submit"
          variant="contained"
        >
          {isEditMode ? 'Save changes' : 'Add new video'}
        </Button>
      </Box>
    </form>
  );
};

export default AssetForm;
