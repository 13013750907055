// src/components/Common/EmbedStep/EmbedStep.tsx

import React, { useState, useCallback, memo } from 'react';
import { StyledPaper, ScrollableContent } from './styles';
import { EmbedStepProps } from './types';
import CodeSection from './common/CodeSection';
import InstructionTabs from './common/InstructionTabs';
import SupportSection from './common/SupportSection';
import { appConfig } from '../../app-config';
import { TestIds } from '../../test-utils';

export const generateEmbedCode = (controllerId: string | null, clientId: string | undefined) => {
  return `<script id="closer2-widget"
    src="https://cdn.eu-west-1${appConfig.isPreProd ? '.dev' : ''}.closer2.io/scripts/widget.js"
    data-c2-controller-id="${controllerId}"
    data-c2-client-id="${clientId}"
    async>
  </script>`;
};

const EmbedStep = memo(({ controllerId, clientId }: EmbedStepProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [showFullScript, setShowFullScript] = useState(false);

  const embedCode = generateEmbedCode(controllerId, clientId);

  const handleCopyCode = useCallback(() => {
    navigator.clipboard.writeText(embedCode);
  }, [embedCode]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const handleToggleScript = useCallback(() => {
    setShowFullScript((prev) => !prev);
  }, []);

  return (
    <StyledPaper elevation={3}>
      <ScrollableContent data-testId={TestIds.EMBED_PAGE.CONTAINER}>
        <CodeSection
          embedCode={embedCode}
          onCopyCode={handleCopyCode}
          onToggleScript={handleToggleScript}
          showFullScript={showFullScript}
        />

        <InstructionTabs onTabChange={handleTabChange} tabValue={tabValue} />
      </ScrollableContent>

      <SupportSection />
    </StyledPaper>
  );
});

export default EmbedStep;
