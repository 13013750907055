// src/components/EmbedInstructionsPage/EmbedInstructionsContainer.tsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useControllersQuery } from '../../hooks/api/controllers/controllers.generated';
import EmbedInstructionsDisplay from './EmbedInstructionsDisplay';
import ErrorBoundary from '../Utils/ErrorBoundry';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';

const EmbedInstructionsContainer = () => {
  const [selectedControllerId, setSelectedControllerId] = useState('');
  const { data: controllersData, loading } = useControllersQuery();
  const customerSettingsMethods = useCustomerSettings();

  const { id } = useParams<{ id: string }>();

  // Set first controller as default when data loads
  useEffect(() => {
    if (
      controllersData?.controllers?.controllers &&
      controllersData?.controllers?.controllers?.length > 0
    ) {
      setSelectedControllerId(id ?? controllersData.controllers.controllers[0].id);
    }
  }, [controllersData, id]);

  const handleControllerChange = (controllerId: string) => {
    setSelectedControllerId(controllerId);
  };

  return (
    <ErrorBoundary>
      <EmbedInstructionsDisplay
        clientId={customerSettingsMethods?.customer?.clientId}
        controllersData={controllersData}
        isLoading={loading}
        onControllerChange={handleControllerChange}
        selectedControllerId={selectedControllerId}
      />
    </ErrorBoundary>
  );
};

export default EmbedInstructionsContainer;
