// components/Assets/AssetCard.tsx
import { Delete } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { AssetsQuery } from '../../hooks/api/assets/assets.generated';

export type Asset = AssetsQuery['assets']['assets'][0] & { previewImage: string }; // TODO a bit hacky..

export interface AssetCardProps {
  asset: Asset;
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  isMobile: boolean;
}

export const AssetCard: React.FC<AssetCardProps> = ({ asset, onEdit, onRemove, isMobile }) => {
  const formatDuration = useCallback((duration: number): string => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }, []);

  const formatBytesToMB = useCallback((bytes: number): string => {
    return (bytes / 1048576).toFixed(2);
  }, []);

  return (
    <Card>
      <CardMedia
        alt={asset.name}
        component="img"
        height="140"
        // image={controller.previewImage}
        image="../video-placeholder.svg"
      />
      <CardContent>
        <Typography component="div" variant="h6">
          {asset.name}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Name: {asset.videoAsset.name}
        </Typography>
        {asset.videoAsset.resolution && (
          <Typography color="text.secondary" variant="body2">
            Resolution: {asset.videoAsset.resolution}
          </Typography>
        )}
        {asset.videoAsset.size && (
          <Typography color="text.secondary" variant="body2">
            Size: {formatBytesToMB(asset.videoAsset.size)} MB
          </Typography>
        )}
        {asset.videoAsset.duration && (
          <Typography color="text.secondary" variant="body2">
            Duration: {formatDuration(asset.videoAsset.duration)}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        {isMobile ? (
          <Tooltip title="Remove">
            <IconButton color="error" onClick={() => onRemove(asset.id)} size="small">
              <Delete />
            </IconButton>
          </Tooltip>
        ) : (
          <Button color="error" onClick={() => onRemove(asset.id)} size="small">
            Remove
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
