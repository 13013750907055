import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode, SyntheticEvent, useState } from 'react';
import { MovieType } from '../../../../interfaces';
import CustomerVideoStatusPill from './CustomerVideoStatusPill';
import { Chevron } from '../../../../assets/icons';

const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <Box sx={{ height: '24px' }}>
        <Chevron />
      </Box>
    }
    {...props}
  />
))(() => ({
  background: '#FFF',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 1,
  },
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 2,
  borderTop: '1px solid',
  borderColor: '#F4F4F4',
  marginLeft: 2,
}));
interface CustomerDetailsProps {
  data?: string | number;
  label: string;
  children?: ReactNode;
}
const CustomerVideoDetails = ({ data, label, children }: CustomerDetailsProps) => {
  return (
    <Box sx={{ flexDirection: 'row', display: 'flex', marginY: 2 }}>
      <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold' }}>{label}</Typography>
      {children}
      <Typography sx={{ color: 'text.priamry', marginLeft: 4 }}>{data}</Typography>
    </Box>
  );
};
interface CustomizedAccordionsProps {
  rows: Partial<MovieType>[];
}
const CustomerVideosMobileAccordion = ({ rows }: CustomizedAccordionsProps) => {
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {rows.map((video, index) => {
        return (
          <StyledAccordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{ borderRadius: index === 0 ? '8px 8px 0px 0px' : null }}
          >
            <StyledAccordionSummary
              aria-controls={`panel${index}-content`}
              id="panel1d-header"
              sx={{
                background: expanded === `panel${index}` ? 'rgba(101, 63, 254, 0.10)' : '#FFF',
                borderRadius: index === 0 ? '8px 8px 0px 0px' : null,
              }}
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>Name</Typography>
              <Typography sx={{ color: 'text.primary', marginLeft: 2 }}>{video.name}</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails sx={{ paddingY: 0 }}>
              <CustomerVideoDetails data={video.url ?? ''} label="URL" />
              <CustomerVideoDetails data={video.numberOfViews ?? ''} label="Number of views" />
              <CustomerVideoDetails data={video.numberOfClicks ?? ''} label="Number of clicks" />
              <CustomerVideoDetails data={video.viewsInFull ?? ''} label="Views in full" />
              <CustomerVideoDetails data={video.ctaClicks ?? ''} label="CTA clics" />
              <CustomerVideoDetails label="CTA clics">
                <CustomerVideoStatusPill isActive={video.isActive} />{' '}
              </CustomerVideoDetails>
            </StyledAccordionDetails>
          </StyledAccordion>
        );
      })}
    </div>
  );
};
export default CustomerVideosMobileAccordion;
