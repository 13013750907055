import { AtIcon, CodeIcon, UsageIcon, VideoIcon } from '../assets/icons';

export const sidebarItems = [
  { path: '/new-controller-wizard', label: 'Create new controller', icon: VideoIcon },
  { path: '/assets', label: 'Video library', icon: VideoIcon },
  { path: '/controllers', label: 'Your controllers', icon: VideoIcon },
  { path: '/leads', label: 'Leads', icon: AtIcon },
  // { path: '/analytics', label: 'Analytics (beta)', icon: SupportIcon },
  { path: '/analytics', label: 'Analytics', icon: UsageIcon },
  { path: '/embed-instructions', label: 'Instructions', icon: CodeIcon },
];
