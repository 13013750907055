// effectively update, shall we merge wit huseControllerCreation?

import { useCreateControllerMutation } from '../../../../../hooks/api/createController/createController.generated';
import { useUpdateControllerMutation } from '../../../../../hooks/api/updateController/updateController.generated';
import { useWizard } from '../../../context/WizardContext';
import { ControllerDraft } from '../../../types';

// TODO this should replace useControllerCreation
export const useControllerOperations = () => {
  const { state, dispatch } = useWizard();
  const [createController] = useCreateControllerMutation();
  const [updateController] = useUpdateControllerMutation();

  const saveController = async (draftToSave: ControllerDraft) => {
    try {
      dispatch({ type: 'SET_SAVING', payload: true });

      // If we have an original controller, this is an update
      if (state.originalController) {
        console.log('Updating controller', draftToSave); // TODO cleanup
        const { id, ...draftWithoutId } = draftToSave;
        const result = await updateController({
          variables: {
            id: state.controllerId!,
            input: draftWithoutId,
          },
        });
        return result.data?.updateController;
      }

      // Otherwise it's a new controller
      const result = await createController({
        variables: {
          input: draftToSave,
        },
      });
      return result.data?.createController;
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error as Error });
      throw error;
    } finally {
      dispatch({ type: 'SET_SAVING', payload: false });
    }
  };

  return { saveController };
};
