import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type MoviesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MoviesQuery = {
  __typename?: 'Query';
  movies: {
    __typename?: 'MoviesQueryResultType';
    movies: Array<{
      __typename?: 'MovieType';
      id: string;
      customerId: string;
      companyName?: string | null;
      name: string;
      isActive: boolean;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      url: string;
      numberOfViews?: number | null;
      numberOfClicks?: number | null;
      viewsInFull?: number | null;
      ctaClicks?: number | null;
    }>;
  };
};

export const MoviesDocument = gql`
  query movies {
    movies {
      movies {
        id
        customerId
        companyName
        name
        isActive
        createdAt
        updatedAt
        deletedAt
        url
        numberOfViews
        numberOfClicks
        viewsInFull
        ctaClicks
      }
    }
  }
`;

/**
 * __useMoviesQuery__
 *
 * To run a query within a React component, call `useMoviesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoviesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoviesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoviesQuery(
  baseOptions?: Apollo.QueryHookOptions<MoviesQuery, MoviesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoviesQuery, MoviesQueryVariables>(MoviesDocument, options);
}
export function useMoviesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MoviesQuery, MoviesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MoviesQuery, MoviesQueryVariables>(MoviesDocument, options);
}
export type MoviesQueryHookResult = ReturnType<typeof useMoviesQuery>;
export type MoviesLazyQueryHookResult = ReturnType<typeof useMoviesLazyQuery>;
export type MoviesQueryResult = Apollo.QueryResult<MoviesQuery, MoviesQueryVariables>;
export function refetchMoviesQuery(variables?: MoviesQueryVariables) {
  return { query: MoviesDocument, variables };
}
