// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/components/SchedulingConfig.tsx

import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useWizard } from '../../../context/WizardContext';
import { useConfigurationUpdates } from '../hooks/useConfigurationUpdates';

export const SchedulingConfig: React.FC = () => {
  const { state } = useWizard();
  const { updateConfiguration } = useConfigurationUpdates();
  const { isAllTime = true, scheduling = { validFrom: null, validUntil: null } } =
    state.configurationConfig || {};

  const handleDateChange = (type: 'validFrom' | 'validUntil', date: Date | null) => {
    updateConfiguration(
      {
        scheduling: {
          ...scheduling,
          [type]: date ? date.toISOString() : null,
        },
      },
      true,
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Typography gutterBottom variant="subtitle1">
          Scheduling
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={isAllTime}
              onChange={(e) => {
                updateConfiguration(
                  {
                    isAllTime: e.target.checked,
                    scheduling: {
                      validFrom: null,
                      validUntil: null,
                    },
                  },
                  true,
                );
              }}
            />
          }
          label="Active at all times"
        />

        {!isAllTime && (
          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            <DatePicker
              label="Valid from"
              onChange={(date) => handleDateChange('validFrom', date)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: false, // Add error handling if needed
                },
              }}
              value={scheduling && scheduling.validFrom ? new Date(scheduling.validFrom) : null}
            />
            <DatePicker
              label="Valid until"
              onChange={(date) => handleDateChange('validUntil', date)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: false, // Add error handling if needed
                },
              }}
              value={scheduling && scheduling?.validUntil ? new Date(scheduling.validUntil) : null}
            />
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};
