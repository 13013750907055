import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { TabPanel } from '@mui/lab';
import { Box, Button, Grid, MenuItem, Modal, Typography } from '@mui/material';
import WidgetControllerPreview from '../Video/WidgetControllerPreview';
import { WidgetControllerFieldsEnum } from '../types';
import { useAssetsQuery } from '../../hooks/api/assets/assets.generated';
import ReactHookFormSelect from '../Common/ReactFormSelect';
import { AddActionsWizardComponent } from './AddActionsWizard';
import { ActionsEditableList } from './ActionsEditableList';
import { ActionData } from './ActionData.model';
import { TextInput } from '../Inputs';

// TODO
// register actions to form state? or map it to request when saving
// make sure switching between tabs is not loosing actions

// modal
const CustomModal: FC<{ open: boolean; onClose: () => void; component: React.ReactNode }> = ({
  open,
  onClose,
  component,
}) => {
  const modalStyles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles}>{component}</Box>
    </Modal>
  );
};

export interface FullScreenTabViewProps {
  register: any;
  errors: any;
  editMode?: boolean;
  setValue: any;
  maxWidthValue: any;
  formValues: any;
  selected?: string;
  control: any;
  actionDataList: ActionData[];
  setActionDataList: Dispatch<SetStateAction<ActionData[]>>;
}

export const FullScreenTabView: FC<FullScreenTabViewProps> = ({
  register,
  errors,
  actionDataList,
  setActionDataList,
  editMode,
  setValue,
  maxWidthValue,
  formValues,
  selected,
  control,
}) => {
  console.log('selected', selected);
  const { data: assetsData } = useAssetsQuery();
  const availableAssets =
    assetsData?.assets.assets.map((a) => ({
      previewImage: 'https://www.clapa.com/wp-content/uploads/2019/06/video-placeholder.jpg',
      id: a.id,
      customerId: a.customerId,
      name: a.name,
      createdAt: a.createdAt,
      updatedAt: a.updatedAt,
      deletedAt: a.deletedAt,
      videoAsset: {
        fileId: a.videoAsset.fileId,
        name: a.videoAsset.name,
      },
    })) || [];

  const [actionsModalOpen, setActionsModalOpen] = useState(false);

  const openActionsModal = () => setActionsModalOpen(true);
  const closeActionsModal = () => setActionsModalOpen(false);

  useEffect(() => {
    // TODO hacky but for now will do as we only have one single stage
    if (
      formValues[WidgetControllerFieldsEnum.Stages] &&
      formValues[WidgetControllerFieldsEnum.Stages][0]
    ) {
      setValue(WidgetControllerFieldsEnum.Stages, [
        {
          ...formValues[WidgetControllerFieldsEnum.Stages][0],
          actions: actionDataList,
        },
      ]);
    }
  }, [actionDataList]);

  return (
    <>
      <TabPanel value="fullscreen">
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12} xs={12}>
            <Box display="flex" flexDirection="column" gap={3}>
              <Typography fontSize="24px" fontWeight={700} pb={2}>
                Settings
              </Typography>
              <TextInput
                {...register(WidgetControllerFieldsEnum.Name)}
                errors={errors}
                label="Controller name"
                onChange={(value) => setValue(WidgetControllerFieldsEnum.Name, value.target.value)}
              />
              <Typography fontSize="18px" fontWeight={500}>
                Videos
              </Typography>
              {(selected || !editMode) && (
                <>
                  <ReactHookFormSelect
                    control={control}
                    defaultValue={selected}
                    label="Choose video from your library"
                    name="videoAssetsSelect"
                    onChange={(e: any) => {
                      const selectedAsset = e.target.value;
                      const selectedAssetDetails = availableAssets.find(
                        (asset) => asset.id === selectedAsset,
                      );
                      if (selectedAssetDetails) {
                        setValue(WidgetControllerFieldsEnum.Stages, [
                          {
                            id: '0',
                            name: selectedAssetDetails.name,
                            content: {
                              id: selectedAssetDetails.id,
                              type: 'video',
                              videoAssetId: selectedAssetDetails.videoAsset.fileId,
                              videoAssetName: selectedAssetDetails.videoAsset.name,
                              videoAssetUrl: selectedAssetDetails.videoAsset.fileId,
                            },
                            actions: [],
                          },
                        ]);
                      }
                    }}
                  >
                    {availableAssets.map((asset, index) => (
                      <MenuItem key={index} value={asset.id}>
                        {asset.name} - {asset.videoAsset.name}
                      </MenuItem>
                    ))}
                  </ReactHookFormSelect>
                  <Button disabled>Add more views (coming soon...)</Button>
                </>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={3}>
              <Typography fontSize="18px" fontWeight={500}>
                Actions
                <Typography fontSize="14px" fontWeight={300}>
                  Choose actions to your buttons
                </Typography>
              </Typography>
              <ActionsEditableList actions={actionDataList} setActions={setActionDataList} />
              <Button onClick={() => openActionsModal()}>Add action</Button>
            </Box>
            <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue} />
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Typography fontSize="24px" fontWeight={700} pb={6}>
              Preview
            </Typography>
            <WidgetControllerPreview data={formValues} isOpen />
          </Grid>
        </Grid>
      </TabPanel>
      {actionsModalOpen && (
        <CustomModal
          component={
            <AddActionsWizardComponent
              onClose={() => closeActionsModal()}
              onSave={(data) => {
                setActionDataList((prevActionDataList) => [
                  ...prevActionDataList,
                  { ...data, order: prevActionDataList.length },
                ]);
                // TODO cleanup
                console.log('onSave data', data);
                console.log('onSave actionDataList', actionDataList);
              }}
            />
          }
          onClose={() => closeActionsModal()}
          open={actionsModalOpen}
        />
      )}
    </>
  );
};
