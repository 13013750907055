// src/components/EmbedInstructionsPage/EmbedInstructionsDisplay.tsx

import React, { memo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { LoadingSpinner } from '../Utils';
import { EmbedInstructionsDisplayProps } from './types';
import ControllerSelect from '../Common/ControllerSelect';
import EmbedStep from '../EmbedStep';

const NoControllersMessage = () => (
  <Box sx={{ textAlign: 'center', py: 4 }}>
    <Typography color="text.secondary" gutterBottom variant="h6">
      No controllers available
    </Typography>
    <Typography color="text.secondary" variant="body2">
      Create a controller first to get your embed code.
    </Typography>
  </Box>
);

const EmbedInstructionsDisplay = memo(
  ({
    clientId,
    controllersData,
    selectedControllerId,
    onControllerChange,
    isLoading,
  }: EmbedInstructionsDisplayProps) => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (!controllersData?.controllers?.controllers?.length) {
      return <NoControllersMessage />;
    }

    return (
      <Box sx={{ maxWidth: 'lg', mx: 'auto', p: 3 }}>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography gutterBottom variant="h6">
            Select Controller
          </Typography>

          <ControllerSelect
            controllersData={controllersData}
            disabled={isLoading}
            onControllerChange={onControllerChange}
            selectedControllerId={selectedControllerId}
          />
        </Paper>

        {selectedControllerId && (
          <EmbedStep clientId={clientId} controllerId={selectedControllerId} />
        )}
      </Box>
    );
  },
);

export default EmbedInstructionsDisplay;
