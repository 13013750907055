// src/components/Common/TrustedDomainsManager/TrustedDomainsManager.tsx
import React, { useState } from 'react';
import { Box, TextField, Chip, Typography, Button, Alert } from '@mui/material';
import { CloseIcon, PlusIcon } from '../../../assets/icons';
import { TrustedDomainsManagerProps } from './types';

const TrustedDomainsManager: React.FC<TrustedDomainsManagerProps> = ({
  domains,
  domainLimit,
  isLoading,
  onAddDomain,
  onRemoveDomain,
  title = 'Trusted Domains',
  description = 'Add domains where your widget will be hosted. Only listed domains will be able to load the widget.',
}) => {
  const [newDomain, setNewDomain] = useState('');
  const [error, setError] = useState('');

  const validateDomain = (domain: string) => {
    const pattern = /([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/;
    return pattern.test(domain);
  };

  const handleAddDomain = async () => {
    try {
      setError('');

      if (!newDomain) {
        setError('Domain cannot be empty');
        return;
      }

      if (!validateDomain(newDomain)) {
        setError('Please enter a valid domain (e.g., example.com)');
        return;
      }

      if (domains.includes(newDomain)) {
        setError('This domain is already in the list');
        return;
      }

      if (domains.length >= domainLimit) {
        setError(`Cannot add more domains. Limit of ${domainLimit} domains reached.`);
        return;
      }

      await onAddDomain(newDomain);
      setNewDomain('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add domain. Please try again.');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !isLoading && newDomain) {
      e.preventDefault();
      handleAddDomain();
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography gutterBottom variant="subtitle1">
        {title} ({domains.length}/{domainLimit})
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Typography color="text.secondary" gutterBottom variant="body2">
          {description}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
        <TextField
          disabled={isLoading}
          error={!!error}
          fullWidth
          inputProps={{
            'aria-label': 'Add domain',
          }}
          onChange={(e) => setNewDomain(e.target.value.toLowerCase())}
          onKeyPress={handleKeyPress}
          placeholder="example.com"
          size="small"
          value={newDomain}
        />
        <Button
          aria-label="Add domain"
          disabled={isLoading || !newDomain}
          onClick={handleAddDomain}
          startIcon={<PlusIcon />}
          variant="contained"
        >
          Add
        </Button>
      </Box>

      {error && (
        <Alert onClose={() => setError('')} severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {domains.map((domain) => (
          <Chip
            key={domain}
            deleteIcon={<CloseIcon />}
            disabled={isLoading}
            label={domain}
            onDelete={() => onRemoveDomain(domain)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TrustedDomainsManager;
