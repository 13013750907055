import React from 'react';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import { GetAnalyticsQuery } from '../../hooks/api/analytics/getAnalytics.generated';

interface AnalyticsDataProps {
  data: GetAnalyticsQuery['getAnalytics'] | null | undefined;
  loading: boolean;
  error: Error | undefined;
}

interface InteractionData {
  [key: string]: number;
}

const AnalyticsData: React.FC<AnalyticsDataProps> = ({ data, loading, error }) => {
  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">Error: {error.message}</Alert>;
  if (!data) return <Typography>No data available</Typography>;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Analytics Data
      </Typography>
      <Typography>Customer ID: {data.customerId}</Typography>
      <Typography>Controller ID: {data.controllerId}</Typography>
      <Typography>Timestamp: {new Date(data.timestamp).toLocaleString()}</Typography>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Views and Plays
      </Typography>
      <Typography>Total Views: {data.views}</Typography>
      <Typography>Unique Views: {data.uniqueViews}</Typography>
      <Typography>Total Plays: {data.plays}</Typography>
      <Typography>Unique Plays: {data.uniquePlays}</Typography>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Actions
      </Typography>
      <Typography>Total Actions: {data.actions.total}</Typography>
      {Object.entries(data.actions.byType as InteractionData).map(([actionType, count]) => (
        <Typography key={actionType}>
          {actionType}: {count}
        </Typography>
      ))}

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Widget Interactions
      </Typography>
      {Object.entries(data.widgetInteractions as InteractionData).map(
        ([interactionType, count]) => (
          <Typography key={interactionType}>
            {interactionType}: {count}
          </Typography>
        ),
      )}

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        User Interactions
      </Typography>
      {Object.entries(data.userInteractions as InteractionData).map(([interactionType, count]) => (
        <Typography key={interactionType}>
          {interactionType}: {count}
        </Typography>
      ))}

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Session Data
      </Typography>
      <Typography>
        Average Session Length: {data.averageSessionLength.toFixed(2)} seconds
      </Typography>
    </Box>
  );
};

export default AnalyticsData;
