import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type GetViewerDataQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  sortBy: Types.Scalars['String']['input'];
  sortOrder: Types.Scalars['String']['input'];
  controllerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  stageId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetViewerDataQuery = {
  __typename?: 'Query';
  viewerData: {
    __typename?: 'ViewerDataResponse';
    data: Array<{
      __typename?: 'ViewerDataItem';
      id: string;
      submissionType: string;
      controllerId: string;
      stageId: string;
      status: string;
      createdAt: string;
      data: {
        __typename?: 'ViewerDataContent';
        email?: string | null;
        phone?: string | null;
        text?: string | null;
      };
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      total: number;
      page: number;
      limit: number;
      totalPages: number;
    };
  };
};

export const GetViewerDataDocument = gql`
  query GetViewerData(
    $page: Int!
    $limit: Int!
    $sortBy: String!
    $sortOrder: String!
    $controllerId: String
    $stageId: String
  ) {
    viewerData(
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
      controllerId: $controllerId
      stageId: $stageId
    ) {
      data {
        id
        submissionType
        controllerId
        stageId
        data {
          email
          phone
          text
        }
        status
        createdAt
      }
      meta {
        total
        page
        limit
        totalPages
      }
    }
  }
`;

/**
 * __useGetViewerDataQuery__
 *
 * To run a query within a React component, call `useGetViewerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerDataQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      controllerId: // value for 'controllerId'
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useGetViewerDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetViewerDataQuery, GetViewerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetViewerDataQuery, GetViewerDataQueryVariables>(
    GetViewerDataDocument,
    options,
  );
}
export function useGetViewerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetViewerDataQuery, GetViewerDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetViewerDataQuery, GetViewerDataQueryVariables>(
    GetViewerDataDocument,
    options,
  );
}
export type GetViewerDataQueryHookResult = ReturnType<typeof useGetViewerDataQuery>;
export type GetViewerDataLazyQueryHookResult = ReturnType<typeof useGetViewerDataLazyQuery>;
export type GetViewerDataQueryResult = Apollo.QueryResult<
  GetViewerDataQuery,
  GetViewerDataQueryVariables
>;
export function refetchGetViewerDataQuery(variables: GetViewerDataQueryVariables) {
  return { query: GetViewerDataDocument, variables };
}
