import { useCallback } from 'react';
import * as xlsx from 'xlsx';

export type ExportToXlsxSheetsParams = Array<{
  name: string;
  headers: string[];
  data: any[][];
}>;

interface ExportToXlsxParams {
  fileName: string;
  sheets: ExportToXlsxSheetsParams;
}

export const useExcel = () => {
  const exportToXlsx = useCallback((params: ExportToXlsxParams) => {
    const workBook = xlsx.utils.book_new();

    // eslint-disable-next-line no-restricted-syntax
    for (const sheetData of params.sheets) {
      const sheet = xlsx.utils.aoa_to_sheet([sheetData.headers, ...sheetData.data]);

      xlsx.utils.book_append_sheet(workBook, sheet, sheetData.name);
    }

    xlsx.writeFile(workBook, `${params.fileName}.xlsx`);
  }, []);

  return {
    exportToXlsx,
  };
};
