import { Route, Routes } from 'react-router-dom';
import { Home, Support } from '../components';
import AddNewAssetView from '../components/Assets/AddNewAssetView';
import AddNewControllerView from '../components/Controllers/AddNewControllerView';
import { Settings } from '../components/Settings';
import { ViewerData } from '../components/ViewerData';
import { AnalyticsView } from '../components/Analytics';
import AssetsView from '../components/Assets/AssetsView';
import ControllersView from '../components/Controllers/ControllersView';
import { SubscriptionPlansList } from '../components/Onboarding/SubscriptionPlan';
import { Checkout } from '../components/Onboarding/Checkout';
import EmbedInstructionsContainer from '../components/EmbedInstructionsPage';
import { Profile } from '../components/Profile';
import { Usage } from '../components/Usage';
import CreateNewControllerWizardV2 from '../components/CreateNewControllerWizardV2';
import EditController from '../components/EditController/EditController';

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<CreateNewControllerWizardV2 />} path="/" />
      <Route element={<AddNewAssetView />} path="/assets/add-new-asset" />
      <Route element={<AssetsView />} path="/assets" />
      <Route element={<AddNewAssetView />} path="/assets/:assetId" />
      <Route element={<ControllersView />} path="/controllers" />
      <Route element={<EditController />} path="/controllers/:controllerId" />
      <Route element={<AddNewControllerView />} path="/controllers/add-new-controller" />
      <Route element={<Support />} path="/support" />
      <Route element={<ViewerData />} path="/leads" />
      <Route element={<Settings />} path="/settings" />
      <Route element={<EmbedInstructionsContainer />} path="/embed-instructions/:id" />
      <Route element={<EmbedInstructionsContainer />} path="/embed-instructions" />
      <Route element={<Usage />} path="/analytics" />
      <Route element={<AnalyticsView />} path="/analytics" />
      <Route element={<CreateNewControllerWizardV2 />} path="/new-controller-wizard" />
      <Route element={<Profile />} path="/profile" />
      <Route path="/plans" element={<SubscriptionPlansList />} />
      <Route path="/checkout" element={<Checkout />} />
    </Routes>
  );
};

export default AppRouter;
