// src/components/EmbedInstructionsPage/EmbedInstructionsContainer.tsx
import React, { memo } from 'react';
import { Box, Collapse } from '@mui/material';
import { Copy, ChevronUp, ChevronDown } from 'lucide-react';
import { CodeBlock, CopyButton } from '../styles';
import { CodeSectionProps } from '../types';

const CodeSection = memo(
  ({ embedCode, onCopyCode, showFullScript, onToggleScript }: CodeSectionProps) => {
    return (
      <>
        <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
          <CopyButton onClick={onCopyCode} startIcon={<Copy size={18} />} variant="contained">
            COPY WIDGET CODE
          </CopyButton>
          <CopyButton
            variant="text"
            onClick={onToggleScript}
            endIcon={showFullScript ? <ChevronUp /> : <ChevronDown />}
            sx={{ color: '#666' }}
          >
            {showFullScript ? 'HIDE CODE' : 'SHOW CODE'}
          </CopyButton>
        </Box>

        <Collapse in={showFullScript}>
          <CodeBlock>
            <code>{embedCode}</code>
          </CodeBlock>
        </Collapse>
      </>
    );
  },
);

export default CodeSection;
