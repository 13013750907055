// src/components/CreateNewControllerWizardV2/index.tsx

import React from 'react';
import { Box } from '@mui/material';
import { useWizard, WizardProvider } from './context/WizardContext';
import { WizardStepper } from './components/WizardStepper';
import { WizardNavigation } from './components/WizardNavigation';
import VideoUploadStep from './steps/VideoUploadStep';
import StyleStep from './steps/StyleStep';
import ConfigureStep from './steps/ConfigureStep';
import EmbedStep from '../EmbedStep';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';

const WizardContent: React.FC = () => {
  const { state } = useWizard();
  const customerSettingsMethods = useCustomerSettings();

  const renderStep = () => {
    switch (state.currentStep) {
      case 0:
        return <VideoUploadStep />;
      case 1:
        return <StyleStep />;
      case 2:
        return <ConfigureStep />;
      case 3:
        return (
          <EmbedStep
            clientId={customerSettingsMethods.customer?.clientId}
            controllerId={state.controllerId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
        pb: 8, // Add padding at bottom to account for fixed navigation
      }}
    >
      <WizardStepper />
      <Box sx={{ flex: 1 }}>{renderStep()}</Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          p: 2,
          zIndex: 1000,
          // Account for the left sidebar width
          ml: { sm: '240px' },
        }}
      >
        <Box sx={{ maxWidth: 1200, mx: 'auto' }}>
          <WizardNavigation />
        </Box>
      </Box>
    </Box>
  );
};

const CreateNewControllerWizardV2: React.FC = () => {
  return (
    <WizardProvider>
      <WizardContent />
    </WizardProvider>
  );
};

export default CreateNewControllerWizardV2;
