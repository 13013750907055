// src/hooks/useTrustedDomains.ts
import { useCustomerSettings } from '../contexts/CustomerSettingsContext';
import { usePatchCustomerSettingsMutation } from './api/patchCustomerSettings/patchCustomerSettings.generated';

export const useTrustedDomains = () => {
  const { customer, refetchCustomer } = useCustomerSettings();
  const [patchCustomerSettings, { loading }] = usePatchCustomerSettingsMutation();

  const domains = customer?.customerSettings?.trustedDomains || [];
  const domainLimit = customer?.currentSubscription?.limits?.domainLimit || 0;

  const handleAddDomain = async (domain: string) => {
    await patchCustomerSettings({
      variables: {
        input: {
          trustedDomains: [...domains, domain],
        },
      },
    });
    await refetchCustomer();
  };

  const handleRemoveDomain = async (domainToRemove: string) => {
    await patchCustomerSettings({
      variables: {
        input: {
          trustedDomains: domains.filter((d) => d !== domainToRemove),
        },
      },
    });
    await refetchCustomer();
  };

  return {
    domains,
    domainLimit,
    isLoading: loading,
    handleAddDomain,
    handleRemoveDomain,
  };
};
