import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type ControllersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ControllersQuery = {
  __typename?: 'Query';
  controllers: {
    __typename?: 'ControllersQueryResultType';
    controllers: Array<{
      __typename?: 'ControllerType';
      id: string;
      customerId: string;
      name: string;
      isActive: boolean;
      validFrom?: string | null;
      validUntil?: string | null;
      url?: string | null;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    }>;
  };
};

export const ControllersDocument = gql`
  query controllers {
    controllers {
      controllers {
        id
        customerId
        name
        isActive
        validFrom
        validUntil
        url
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useControllersQuery__
 *
 * To run a query within a React component, call `useControllersQuery` and pass it any options that fit your needs.
 * When your component renders, `useControllersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControllersQuery({
 *   variables: {
 *   },
 * });
 */
export function useControllersQuery(
  baseOptions?: Apollo.QueryHookOptions<ControllersQuery, ControllersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ControllersQuery, ControllersQueryVariables>(ControllersDocument, options);
}
export function useControllersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ControllersQuery, ControllersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ControllersQuery, ControllersQueryVariables>(
    ControllersDocument,
    options,
  );
}
export type ControllersQueryHookResult = ReturnType<typeof useControllersQuery>;
export type ControllersLazyQueryHookResult = ReturnType<typeof useControllersLazyQuery>;
export type ControllersQueryResult = Apollo.QueryResult<
  ControllersQuery,
  ControllersQueryVariables
>;
export function refetchControllersQuery(variables?: ControllersQueryVariables) {
  return { query: ControllersDocument, variables };
}
