// hooks/useAssetForm.ts
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { videoAssetsSchema } from '../components/Assets/videoAssetsSchema';
import { useCreateAssetMutation } from './api/createAsset/createAsset.generated';
import { AssetsDocument } from './api/assets/assets.generated';
import { useUpdateAssetMutation } from './api/updateAssets/updateAssets.generated';
import { AssetFormValues } from '../components/Assets/AssetForm';

export const useAssetForm = (id?: string) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<AssetFormValues>({
    resolver: yupResolver(videoAssetsSchema()),
  });

  const [createAsset] = useCreateAssetMutation({
    refetchQueries: [{ query: AssetsDocument }],
  });

  const [updateAsset] = useUpdateAssetMutation({
    refetchQueries: [{ query: AssetsDocument }],
  });

  const onSubmit = async (values: AssetFormValues) => {
    setIsSubmitting(true);
    try {
      if (id) {
        await updateAsset({
          variables: {
            id,
            input: values,
          },
        });
      } else {
        await createAsset({
          variables: {
            input: values,
          },
        });
      }
      navigate('/assets');
    } catch (err) {
      setError((err as Error)?.message || 'Unknown error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    isSubmitting,
    error,
    setError,
    onSubmit,
  };
};
