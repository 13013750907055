import { Box, CircularProgress } from '@mui/material';

function LoadingSpinner({ size = 100 }) {
  return (
    <Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
      <CircularProgress size={size} />
    </Box>
  );
}

export default LoadingSpinner;
