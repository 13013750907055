// src/components/ViewerData/components/DataFilters.tsx

import React, { memo } from 'react';
import { Box, Button } from '@mui/material';
import { DataFiltersProps } from '../types';
import ControllerSelect from '../../Common/ControllerSelect';

const DataFilters = memo(
  ({ selectedControllerId, controllersData, onControllerChange, onExport }: DataFiltersProps) => {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '3fr 1fr', // On mobile: 75% / 25% split
            md: '3fr 1fr', // On desktop: maintain the same ratio
          },
          gap: 2,
          mb: 3,
          width: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <ControllerSelect
            controllersData={controllersData}
            disabled={!controllersData || controllersData.controllers.controllers.length === 0}
            onControllerChange={onControllerChange}
            selectedControllerId={selectedControllerId}
          />
        </Box>
        <Button
          color="secondary"
          onClick={onExport}
          sx={{
            px: { xs: 2.5, md: 11.25 },
            py: 1.5,
            textTransform: 'none',
            height: '56px', // Match height with select
          }}
          variant="outlined"
        >
          Export
        </Button>
      </Box>
    );
  },
);

export default DataFilters;
