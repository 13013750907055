import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useStyleContext } from './useStyleContext';
import { StyleChanges } from '../../CreateNewControllerWizardV2/steps/StyleStep/types';

interface WidgetSizeConfigProps {
  onStyleChange: (changes: StyleChanges) => void;
}

export const WidgetSizeConfig: React.FC<WidgetSizeConfigProps> = ({ onStyleChange }) => {
  const { state } = useStyleContext();

  const handleSizeChange = (size: 'S' | 'M' | 'L') => {
    onStyleChange({ widgetSize: size });
  };

  return (
    <>
      <Typography gutterBottom variant="subtitle1">
        Widget Size
      </Typography>
      <FormControl fullWidth>
        <InputLabel>Size</InputLabel>
        <Select
          label="Size"
          onChange={(e) => handleSizeChange(e.target.value as 'S' | 'M' | 'L')}
          value={state.styleConfig?.widgetSize || 'M'}
        >
          <MenuItem value="S">Small</MenuItem>
          <MenuItem value="M">Medium</MenuItem>
          <MenuItem value="L">Large</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
