// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/components/DomainConfig.tsx

import { useTrustedDomains } from '../../../../../hooks/useTrustedDomains';
import TrustedDomainsManager from '../../../../Common/TrustedDomainsManager/TrustedDomainsManager';

export const DomainConfig: React.FC = () => {
  const { domains, domainLimit, isLoading, handleAddDomain, handleRemoveDomain } =
    useTrustedDomains();

  return (
    <TrustedDomainsManager
      description="Configure domains where your widget will be accessible from"
      domainLimit={domainLimit}
      domains={domains}
      isLoading={isLoading}
      onAddDomain={handleAddDomain}
      onRemoveDomain={handleRemoveDomain}
      title="Widget Domains"
    />
  );
};
