import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  SelectChangeEvent,
} from '@mui/material';
import CustomToggleButtonGroup from '../Inputs/ToggleButtonGroup';
import WidgetControllerPreview from '../Video/WidgetControllerPreview';
import ColorPicker from '../Common/ColorPicker';

interface MinifiedTabViewProps {
  register: any;
  formValues: any;
  setValue: any;
  maxWidthValue: string | number;
}

const DEFAULT_POSITION = 'bottomLeft';
const DEFAULT_MARGIN = 20;
const MAX_BUBBLE_TEXT_LENGTH = 100;

export const MinifiedTabView: React.FC<MinifiedTabViewProps> = ({
  register,
  formValues,
  setValue,
  maxWidthValue,
}) => {
  useEffect(() => {
    // Set default values if they're not already set
    if (!formValues.layout.widget.design.minified.positioning) {
      setValue('layout.widget.design.minified.positioning', {
        position: DEFAULT_POSITION,
        [DEFAULT_POSITION]: {
          marginBottom: DEFAULT_MARGIN,
          marginLeft: DEFAULT_MARGIN,
        },
      });
    } else {
      const { position, ...restPositioning } = formValues.layout.widget.design.minified.positioning;

      if (!position) {
        setValue('layout.widget.design.minified.positioning.position', DEFAULT_POSITION);
      }

      const currentPosition = position || DEFAULT_POSITION;

      if (!restPositioning[currentPosition]) {
        setValue(`layout.widget.design.minified.positioning.${currentPosition}`, {
          marginBottom: DEFAULT_MARGIN,
          [currentPosition === 'bottomRight' ? 'marginRight' : 'marginLeft']: DEFAULT_MARGIN,
        });
      }
    }

    // Ensure border.enabled is always a boolean
    if (
      formValues.layout.widget.design.minified.border?.enabled === null ||
      formValues.layout.widget.design.minified.border?.enabled === undefined
    ) {
      setValue('layout.widget.design.minified.border.enabled', false);
    }
  }, [
    formValues.layout.widget.design.minified.positioning,
    formValues.layout.widget.design.minified.border,
    setValue,
  ]);

  useEffect(() => {
    // Ensure addOns structure is initialized
    if (!formValues.layout.widget.design.minified.addOns) {
      setValue('layout.widget.design.minified.addOns', {
        bubble: {
          enabled: false,
          text: '',
          design: {
            backgroundColor: '#FFFFFF',
            textColor: '#000000',
          },
        },
      });
    } else if (!formValues.layout.widget.design.minified.addOns.bubble) {
      setValue('layout.widget.design.minified.addOns.bubble', {
        enabled: false,
        text: '',
        design: {
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
        },
      });
    }
  }, [formValues.layout.widget.design.minified, setValue]);

  // border
  const borderEnabled = formValues.layout.widget.design.minified.border?.enabled ?? false;

  const handleBorderEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('layout.widget.design.minified.border.enabled', event.target.checked);
  };

  const handleBorderColorChange = (color: string | null, gradientColors: string[] | null) => {
    setValue('layout.widget.design.minified.border.color', color);
    setValue('layout.widget.design.minified.border.gradientColors', gradientColors);
  };

  // position
  const handlePositionChange = (event: SelectChangeEvent<any>) => {
    const newPosition = event.target.value as string;
    setValue('layout.widget.design.minified.positioning.position', newPosition);
    // Ensure the new position has margin values
    if (!formValues.layout.widget.design.minified.positioning[newPosition]) {
      setValue(`layout.widget.design.minified.positioning.${newPosition}`, {
        marginBottom: DEFAULT_MARGIN,
        [newPosition === 'bottomRight' ? 'marginRight' : 'marginLeft']: DEFAULT_MARGIN,
      });
    }
  };

  const handleMarginChange = (field: string, value: number) => {
    setValue(`layout.widget.design.minified.positioning.${field}`, value);
  };

  // addons bubble
  const handleBubbleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('layout.widget.design.minified.addOns.bubble.enabled', event.target.checked);
  };

  const handleBubbleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value.slice(0, MAX_BUBBLE_TEXT_LENGTH);
    setValue('layout.widget.design.minified.addOns.bubble.text', text);
  };

  const handleBubbleColorChange = (
    field: 'backgroundColor' | 'textColor',
    color: string | null,
  ) => {
    if (!color) return;
    setValue(`layout.widget.design.minified.addOns.bubble.design.${field}`, color);
  };

  const borderColor = formValues.layout.widget.design.minified.border?.color;
  const borderGradientColors = formValues.layout.widget.design.minified.border?.gradientColors;
  const position =
    formValues.layout.widget.design.minified.positioning?.position || DEFAULT_POSITION;

  const bubbleEnabled = formValues.layout.widget.design.minified.addOns?.bubble?.enabled ?? false;
  const bubbleText = formValues.layout.widget.design.minified.addOns?.bubble?.text ?? '';
  const bubbleBackgroundColor =
    formValues.layout.widget.design.minified.addOns?.bubble?.design?.backgroundColor ?? '#FFFFFF';
  const bubbleTextColor =
    formValues.layout.widget.design.minified.addOns?.bubble?.design?.textColor ?? '#000000';

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} sm={12} xs={12}>
        <Typography fontSize="24px" fontWeight={700} pb={6}>
          Settings
        </Typography>
        <Box display="flex" flexDirection="column" gap={3} pt="3%">
          <CustomToggleButtonGroup
            {...register('layout.widget.design.minified.widgetSize')}
            disabled={false}
            label="Size"
            onChange={(value: string) =>
              setValue('layout.widget.design.minified.widgetSize', value as never)
            }
            value={formValues.layout.widget.design.minified.widgetSize}
            values={['S', 'M', 'L']}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={3} pt="3%">
          <CustomToggleButtonGroup
            {...register('layout.widget.design.minified.shape')}
            disabled={false}
            label="Shape"
            onChange={(value: string) =>
              setValue('layout.widget.design.minified.shape', value as never)
            }
            value={formValues.layout.widget.design.minified.shape}
            values={['square', 'rectangle', 'circle']}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={3} pt="3%">
          <FormControlLabel
            control={<Switch checked={borderEnabled} onChange={handleBorderEnabledChange} />}
            label="Enable Border"
          />
        </Box>
        {formValues.layout.widget.design.minified.border?.enabled && (
          <Box display="flex" flexDirection="column" gap={3} pt="3%">
            <ColorPicker
              color={borderColor}
              gradientColors={borderGradientColors}
              onChangeComplete={handleBorderColorChange}
              label="Border Color"
            />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={3} pt="3%">
          <FormControl>
            <InputLabel>Position</InputLabel>
            <Select label="Position" onChange={handlePositionChange} value={position}>
              <MenuItem value="bottomLeft">Bottom Left</MenuItem>
              <MenuItem value="bottomRight">Bottom Right</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="column" gap={3} pt="3%">
          <TextField
            label={position === 'bottomLeft' ? 'Margin Left' : 'Margin Right'}
            type="number"
            value={
              formValues.layout.widget.design.minified.positioning?.[position]?.[
                `margin${position === 'bottomLeft' ? 'Left' : 'Right'}`
              ] ?? DEFAULT_MARGIN
            }
            onChange={(e) =>
              handleMarginChange(
                `${position}.margin${position === 'bottomLeft' ? 'Left' : 'Right'}`,
                Number(e.target.value),
              )
            }
            InputProps={{ inputProps: { min: 0 } }}
          />
          <TextField
            label="Margin Bottom"
            type="number"
            value={
              formValues.layout.widget.design.minified.positioning?.[position]?.marginBottom ??
              DEFAULT_MARGIN
            }
            onChange={(e) => handleMarginChange(`${position}.marginBottom`, Number(e.target.value))}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue} />
        {/* AddOns Section */}
        <Box display="flex" flexDirection="column" gap={3} pt="3%">
          <Typography variant="h6">Add-Ons</Typography>
          <FormControlLabel
            control={<Switch checked={bubbleEnabled} onChange={handleBubbleEnabledChange} />}
            label="Enable Bubble"
          />
          {bubbleEnabled && (
            <>
              <TextField
                label="Bubble Text"
                value={bubbleText}
                onChange={handleBubbleTextChange}
                inputProps={{ maxLength: MAX_BUBBLE_TEXT_LENGTH }}
                helperText={`${bubbleText.length}/${MAX_BUBBLE_TEXT_LENGTH}`}
              />
              <ColorPicker
                color={bubbleBackgroundColor}
                onChangeComplete={(bubbleBackgroundColorInput) =>
                  handleBubbleColorChange('backgroundColor', bubbleBackgroundColorInput)
                }
                label="Bubble Background Color"
              />
              <ColorPicker
                color={bubbleTextColor}
                onChangeComplete={(bubbleTextColorInput) =>
                  handleBubbleColorChange('textColor', bubbleTextColorInput)
                }
                label="Bubble Text Color"
              />
            </>
          )}
        </Box>
      </Grid>
      <Grid item lg={6} sm={12} xs={12}>
        <Typography fontSize="24px" fontWeight={700} pb={6}>
          Preview
        </Typography>
        <WidgetControllerPreview data={formValues} isOpen />
      </Grid>
    </Grid>
  );
};

// TODO - make color picker have gradient mode and no gradient mode
// TODO - test backwardcompatiblity of bubble text
// TODO - deploy changes to dev to testing
