import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';

interface SubscriptionFieldProps {
  title: string;
  value: string;
}

export const SubscriptionField = memo(({ title, value }: SubscriptionFieldProps) => (
  <Grid item sm={6} xs={12}>
    <Typography gutterBottom variant="h6">
      {title}
    </Typography>
    <Typography
      sx={{
        wordBreak: 'break-word',
        color: 'text.secondary',
      }}
      variant="body1"
    >
      {value}
    </Typography>
  </Grid>
));
