import { FC } from 'react';
import { Box, Grid, Switch, Typography } from '@mui/material';
import DateInput from '../Common/DateInput';
import { WidgetControllerFieldsEnum } from '../types';
import { TextInput } from '../Inputs';

export interface SettingsTabViewProps {
  control: any;
  register: any;
  setValue: any;
  watch: any;
  errors: any;
  maxWidthValue: any;
}

export const SettingsTabView: FC<SettingsTabViewProps> = ({
  control,
  register,
  setValue,
  watch,
  errors,
  maxWidthValue,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} sm={12} xs={12}>
        <Typography fontSize="24px" fontWeight={700} pb={6}>
          Display settings
        </Typography>
        <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography>Active video</Typography>
            <Switch
              {...register(WidgetControllerFieldsEnum.IsActive)}
              color="secondary"
              defaultChecked={watch(WidgetControllerFieldsEnum.IsActive)}
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={3}>
            <DateInput
              control={control}
              errors={errors}
              label="Valid from"
              name={WidgetControllerFieldsEnum.ValidFrom}
            />
            <DateInput
              control={control}
              errors={errors}
              label="Valid until"
              name={WidgetControllerFieldsEnum.ValidUntil}
            />
          </Box>
          <TextInput
            {...register(WidgetControllerFieldsEnum.Url)}
            errors={errors}
            label="Widget url"
            onChange={(value) => setValue(WidgetControllerFieldsEnum.Url, value.target.value)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
