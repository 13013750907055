// ControllerCard.tsx
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Button,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { type FC } from 'react';
import { Edit, Delete, Code } from '@mui/icons-material';
import { TestIds } from '../../test-utils';

interface Controller {
  id: string;
  name: string;
  previewImage: string;
  customerId: string;
  isActive: boolean;
  validFrom?: string | null;
  validUntil?: string | null;
  url?: string | null;
  createdAt: string;
}

interface ControllerCardProps {
  controller: Controller;
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  onCopyEmbed: (id: string) => void;
}

const ControllerCard: FC<ControllerCardProps> = ({ controller, onEdit, onRemove, onCopyEmbed }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card>
      <CardMedia
        alt={controller.name}
        component="img"
        height="140"
        // image={controller.previewImage}
        image="../video-placeholder.svg"
      />
      <CardContent>
        <Typography component="div" variant="h6">
          {controller.name}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {controller.createdAt}
        </Typography>
      </CardContent>
      <CardActions data-testid={TestIds.CONTROLLERS.ITEM}>
        {isMobile ? (
          <>
            <Tooltip title="Edit">
              <IconButton color="primary" onClick={() => onEdit(controller.id)} size="small">
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove">
              <IconButton color="error" onClick={() => onRemove(controller.id)} size="small">
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Embed code">
              <IconButton color="primary" onClick={() => onCopyEmbed(controller.id)} size="small">
                <Code />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Button onClick={() => onEdit(controller.id)} size="small" sx={{ mr: 1 }}>
              Edit
            </Button>
            <Button
              color="error"
              onClick={() => onRemove(controller.id)}
              size="small"
              sx={{ mr: 1 }}
            >
              Remove
            </Button>
            <Button onClick={() => onCopyEmbed(controller.id)} size="small">
              Embed code
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default ControllerCard;
