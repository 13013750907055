// src/components/shared/StyleComponents/useStyleContext.ts
import { useCallback } from 'react';
import { useWizard } from '../../CreateNewControllerWizardV2/context/WizardContext';
import { useEditContext } from '../../EditController/context/EditContext';
import { StyleConfig } from './types';
import { FormHandlers } from '../VideoComponents/types';

interface StyleContextValue {
  state: {
    styleConfig: StyleConfig;
    isSaving: boolean;
  };
  dispatch: (action: { type: string; payload: Partial<StyleConfig> }) => void;
  isWizardMode: boolean;
}

export const useStyleContext = (): StyleContextValue => {
  try {
    // Try Wizard Context first
    const wizardContext = useWizard();
    return {
      state: {
        styleConfig: wizardContext.state.styleConfig,
        isSaving: wizardContext.state.isSaving,
      },
      dispatch: (action: { payload: Partial<StyleConfig> }) =>
        wizardContext.dispatch({
          type: 'UPDATE_STYLE',
          payload: action.payload,
        }),
      isWizardMode: true,
    };
  } catch {
    // Fallback to Edit Context
    const editContext = useEditContext();
    return {
      state: {
        styleConfig: editContext.state.styleChanges || {},
        isSaving: editContext.state.isSaving,
      },
      dispatch: (action) =>
        editContext.dispatch({
          type: 'UPDATE_STYLE',
          payload: action.payload,
        }),
      isWizardMode: false,
    };
  }
};
