import React from 'react';

const MobileMockup: React.FC = () => {
  return (
    <div
      style={{
        width: '300px',
        position: 'relative',
      }}
    >
      <img alt="mobile" src="../phone_036.png" />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: 'white',
          fontSize: '24px',
          fontWeight: 'bold',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        Real-time preview mode is not available yet. Stay tuned!
      </div>
    </div>
  );
};

export default MobileMockup;
