// src/components/CreateNewControllerWizardV2/hooks/useConfigurationUpdates.ts

import { useCallback } from 'react';
import { debounce } from 'lodash';
import { usePatchControllerMutation } from '../../../../../hooks/api/patchController/patchController.generated';
import { ConfigurationConfig } from '../../../types';
import { useWizard } from '../../../context/WizardContext';

export const useConfigurationUpdates = () => {
  const [patchController] = usePatchControllerMutation();
  const { state, dispatch } = useWizard();

  const updateController = useCallback(
    async (changes: Partial<ConfigurationConfig>, immediate = false) => {
      if (!state.controllerId) return;

      // Immediately update local state for better UX
      dispatch({ type: 'UPDATE_CONFIGURATION', payload: changes });

      if (!immediate) {
        // Early return if not immediate - let the debounced function handle the API call
        return;
      }

      try {
        dispatch({ type: 'SET_SAVING', payload: true });

        // Transform changes into path-value pairs
        const patchChanges = [];

        // Handle basic controller properties
        if (changes.name !== undefined) {
          patchChanges.push({ path: 'name', value: changes.name });
        }

        // Handle scheduling
        if (changes.scheduling) {
          if (changes.scheduling.validFrom !== undefined) {
            patchChanges.push({ path: 'validFrom', value: changes.scheduling.validFrom });
          }
          if (changes.scheduling.validUntil !== undefined) {
            patchChanges.push({ path: 'validUntil', value: changes.scheduling.validUntil });
          }
        }

        // Handle action updates
        if (changes.action) {
          if (changes.action.buttonLabel !== undefined) {
            patchChanges.push({
              path: 'stages.0.actions.0.label',
              value: changes.action.buttonLabel,
            });
          }
          if (changes.action.instructionsText !== undefined) {
            patchChanges.push({
              path: 'stages.0.actions.0.emailCollection.instructionsText',
              value: changes.action.instructionsText,
            });
          }
          if (changes.action.disclaimerText !== undefined) {
            patchChanges.push({
              path: 'stages.0.actions.0.emailCollection.disclaimerText',
              value: changes.action.disclaimerText,
            });
          }
        }

        console.log('Patch updates:', { changes: patchChanges });

        const result = await patchController({
          variables: {
            input: {
              id: state.controllerId,
              changes: patchChanges,
            },
          },
        });

        if (!result.data?.patchController) {
          // Revert local state if API call fails
          dispatch({ type: 'SET_ERROR', payload: new Error('Failed to update controller') });
        }
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error as Error });
      } finally {
        dispatch({ type: 'SET_SAVING', payload: false });
      }
    },
    [state.controllerId, patchController, dispatch],
  );

  // Debounce the updates to prevent too many API calls
  const debouncedUpdate = useCallback(
    debounce((changes: Partial<ConfigurationConfig>) => {
      updateController(changes, true);
    }, 1000),
    [updateController],
  );

  return {
    updateConfiguration: (changes: Partial<ConfigurationConfig>, immediate = false) => {
      updateController(changes, immediate);
      debouncedUpdate(changes);
    },
  };
};
