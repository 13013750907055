// src/components/Usage/UsageDisplay.tsx

import React, { memo, useMemo } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { eachDayOfInterval, format, parseISO } from 'date-fns';
import { LoadingSpinner } from '../Utils';
import { TestIds } from '../../test-utils';
import { UsageDisplayProps } from './types';
import { PlanInfo } from './components/PlanInfo';
import { DailyGraph } from './components/DailyGraph';
import { UsageMetrics } from './components/UsageMetrics';

const UsageDisplay = memo(({ data, isLoading, error, isMobile }: UsageDisplayProps) => {
  if (isLoading) return <LoadingSpinner />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;
  if (!data) return <Typography>No data available</Typography>;

  const {
    dailyUsage,
    totalPlaysAllowed,
    totalPlaysUsed,
    totalViewsAllowed,
    totalViewsUsed,
    currentPlan,
  } = data;

  const mergedUsage = useMemo(() => {
    // Generate complete list of dates
    const allDates = eachDayOfInterval({
      start: parseISO(currentPlan.startDate),
      end: parseISO(currentPlan.endDate),
    }).map((date) => format(date, 'yyyy-MM-dd'));

    // Create usage map
    const usageMap = new Map(
      dailyUsage.map((item) => [format(parseISO(item.date), 'yyyy-MM-dd'), item]),
    );

    // Merge data
    return allDates.map((date) => ({
      date,
      plays: usageMap.get(date)?.plays || 0,
      views: usageMap.get(date)?.views || 0,
    }));
  }, [dailyUsage, currentPlan]);

  const remainingPlays = totalPlaysAllowed - totalPlaysUsed;
  const remainingViews = totalViewsAllowed - totalViewsUsed;

  return (
    <Container
      data-testid={TestIds.ANALYTICS.CONTAINER}
      maxWidth="lg"
      sx={{
        py: { xs: 2, md: 4 },
        px: { xs: 1, md: 3 },
        '& .recharts-wrapper': {
          // Ensure charts don't overflow their containers
          maxWidth: '100%',
        },
      }}
    >
      <PlanInfo
        endDate={currentPlan.endDate}
        planName={currentPlan.name}
        startDate={currentPlan.startDate}
      />

      <Box
        sx={{
          height: { xs: 300, md: 400 },
          mb: { xs: 2, md: 4 },
        }}
      >
        <DailyGraph isMobile={isMobile} usageData={mergedUsage} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 2, md: 4 },
        }}
      >
        <UsageMetrics
          remaining={remainingPlays}
          title="Plays Usage"
          total={totalPlaysAllowed}
          used={totalPlaysUsed}
        />

        {/* <UsageMetrics
        remaining={remainingViews}
        title="Views Usage"
        total={totalViewsAllowed}
        used={totalViewsUsed}
      /> */}
      </Box>
    </Container>
  );
});

export default UsageDisplay;
