import {
  Box,
  Card,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { memo } from 'react';
import { SubscriptionDetailsProps } from '../types';
import { SubscriptionField } from './SubscriptionField';

interface ResourceMetrics {
  current: number;
  limit: number;
  remaining: number;
}

interface LimitDisplayProps {
  label: string;
  metrics: ResourceMetrics;
}

const LimitDisplay = memo(({ label, metrics }: LimitDisplayProps) => {
  const theme = useTheme();
  const percentage = (metrics.current / metrics.limit) * 100;
  const isWarning = percentage > 80;

  return (
    <Card sx={{ p: 2 }}>
      <Typography color="text.secondary" gutterBottom variant="subtitle2">
        {label}
      </Typography>
      <Box sx={{ width: '100%', mb: 1 }}>
        <LinearProgress
          color={isWarning ? 'warning' : 'primary'}
          sx={{ height: 8, borderRadius: 4 }}
          value={Math.min(percentage, 100)}
          variant="determinate"
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Tooltip title="Used">
          <Typography color="text.secondary" variant="caption">
            {metrics.current} used
          </Typography>
        </Tooltip>
        <Tooltip title="Remaining">
          <Typography color="text.secondary" variant="caption">
            {metrics.remaining} remaining
          </Typography>
        </Tooltip>
      </Box>
    </Card>
  );
});

const ResourceLimits = memo(
  ({ resources }: { resources: SubscriptionDetailsProps['resources'] }) => (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography gutterBottom variant="h5">
        Resource Usage
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <LimitDisplay label="Controllers" metrics={resources!.controllers} />
        </Grid>
        <Grid item xs={12} md={4}>
          <LimitDisplay label="Videos" metrics={resources!.videos} />
        </Grid>
        <Grid item xs={12} md={4}>
          <LimitDisplay label="Domains" metrics={resources!.domains} />
        </Grid>
      </Grid>
    </Paper>
  ),
);

export const SubscriptionDetails = memo(({ subscription, resources }: SubscriptionDetailsProps) => (
  <>
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography gutterBottom variant="h5">
        Current Subscription
      </Typography>
      {subscription ? (
        <Grid container spacing={2}>
          <SubscriptionField title="Plan" value={subscription.plan.name} />
          <SubscriptionField title="Status" value={subscription.status} />
          <SubscriptionField
            title="Start Date"
            value={format(new Date(subscription.startDate), 'PPP')}
          />
          <SubscriptionField
            title="End Date"
            value={format(new Date(subscription.endDate), 'PPP')}
          />
        </Grid>
      ) : (
        <Typography>No active subscription</Typography>
      )}
    </Paper>

    {resources && <ResourceLimits resources={resources} />}
  </>
));
