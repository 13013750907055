import { FormControl, InputLabel, Select } from '@mui/material';
import { Controller } from 'react-hook-form';

// https://stackoverflow.com/questions/63236951/how-to-use-material-ui-select-with-react-hook-form
const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  onChange,
  ...props
}: {
  name: string;
  label: string;
  control: any;
  defaultValue: any;
  onChange?: any;
  children: React.ReactNode;
}) => {
  const labelId = `${name}-label`;
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field }) => (
          <Select label={label} labelId={labelId} {...field} onChange={onChange}>
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
