import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type GetAssetQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type GetAssetQuery = {
  __typename?: 'Query';
  asset: {
    __typename?: 'AssetType';
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    videoAsset: {
      __typename?: 'UploadedAssetFileResultType';
      fileId: string;
      name: string;
      resolution?: string | null;
      size?: number | null;
      duration?: number | null;
    };
  };
};

export const GetAssetDocument = gql`
  query GetAsset($id: String!) {
    asset(id: $id) {
      id
      name
      videoAsset {
        fileId
        name
        resolution
        size
        duration
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAssetQuery__
 *
 * To run a query within a React component, call `useGetAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssetQuery, GetAssetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetQuery, GetAssetQueryVariables>(GetAssetDocument, options);
}
export function useGetAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssetQuery, GetAssetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetQuery, GetAssetQueryVariables>(GetAssetDocument, options);
}
export type GetAssetQueryHookResult = ReturnType<typeof useGetAssetQuery>;
export type GetAssetLazyQueryHookResult = ReturnType<typeof useGetAssetLazyQuery>;
export type GetAssetQueryResult = Apollo.QueryResult<GetAssetQuery, GetAssetQueryVariables>;
export function refetchGetAssetQuery(variables: GetAssetQueryVariables) {
  return { query: GetAssetDocument, variables };
}
