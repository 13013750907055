// src/components/EditController/components/ClosedControllerSection.tsx
import React from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { WidgetSizeConfig } from '../../Common/StyleComponents/WidgetSizeConfig';
import { useWizard } from '../../CreateNewControllerWizardV2/context/WizardContext';
import { WidgetPreview } from '../../CreateNewControllerWizardV2/shared/WidgetPreview';
import { BorderConfig } from '../../CreateNewControllerWizardV2/steps/StyleStep/components/BorderConfig';
import { PositionConfig } from '../../CreateNewControllerWizardV2/steps/StyleStep/components/PositionConfig';
import { BubbleConfig } from '../../CreateNewControllerWizardV2/steps/StyleStep/components/BubbleConfig';

export const ClosedControllerSection: React.FC = () => {
  const { state, dispatch } = useWizard();

  const handleStyleChange = (changes: any) => {
    const currentLayout = state.draft?.layout;

    if (!currentLayout) return;
    dispatch({
      type: 'UPDATE_DRAFT',
      payload: {
        layout: {
          type: currentLayout.type, // Keep existing type
          widget: {
            ...currentLayout.widget,
            design: {
              ...currentLayout.widget.design,
              minified: {
                ...currentLayout.widget.design.minified,
                ...changes,
              },
            },
          },
        },
      },
    });
  };

  const previewState = {
    draftController: state.draft ?? undefined,
    controllerId: state.controllerId!,
    previewKey: state.previewKey,
    isSaving: state.isSaving,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          {/* Widget Size */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <WidgetSizeConfig onStyleChange={handleStyleChange} />
          </Paper>

          {/* Border Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <BorderConfig onStyleChange={handleStyleChange} />
          </Paper>

          {/* Position Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <PositionConfig onStyleChange={handleStyleChange} />
          </Paper>

          {/* Bubble Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <BubbleConfig onStyleChange={handleStyleChange} />
          </Paper>
        </Stack>
      </Grid>
      {/* Preview Column */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            position: 'sticky',
            top: 24,
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
          }}
        >
          <WidgetPreview previewState={previewState} />
        </Paper>
      </Grid>
    </Grid>
  );
};
