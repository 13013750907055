// TODO error handling notification snackbar
// TODO empty list handling
// TODO graphql settings call fails "ForbiddenException: Empty Authorization header"

import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { useCreateCheckoutSessionMutation } from '../../hooks/api/checkout/checkout.generated';

export const Checkout: React.FC = () => {
  const stripe = useStripe();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId');

  const [createCheckoutSessionMutation, { loading, error }] = useCreateCheckoutSessionMutation({
    variables: {
      planId: planId || '',
    },
  });

  useEffect(() => {
    if (!planId) {
      console.error('Invalid URL parameters. Please check the URL and try again.');
    }
    handleCheckout();
  }, [planId]);

  const handleCheckout = async () => {
    if (!stripe || !planId) return;

    try {
      const result = await createCheckoutSessionMutation();

      console.log('Result:', result);

      if (result.data?.createCheckoutSession?.sessionId) {
        await stripe.redirectToCheckout({
          sessionId: result.data.createCheckoutSession.sessionId,
        });
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return <p>Loading</p>;

  // const stripe = useStripe();
  // const elements = useElements();
  // const [error, setError] = useState<string | null>(null);
  // const [processing, setProcessing] = useState(false);
  // const location = useLocation();

  // const queryParams = new URLSearchParams(location.search);
  // const planId = queryParams.get('planId');
  // const stripePriceId = queryParams.get('stripePriceId');

  // const [createCheckoutSessionMutation, { data, loading }] = useCreateCheckoutSessionMutation({
  //   variables: {
  //     planId: planId || '',
  //   },
  // });

  // useEffect(() => {
  //   if (!planId || !stripePriceId) {
  //     setError('Invalid URL parameters. Please check the URL and try again.');
  //   }
  // }, [planId, stripePriceId]);

  // const handleSubmit = async (event: React.FormEvent) => {
  //   event.preventDefault();

  //   if (!stripe || !elements || !planId) {
  //     return;
  //   }

  //   setProcessing(true);

  //   try {
  //     const result = await createCheckoutSessionMutation();

  //     if (result.data?.createCheckoutSession?.sessionId) {
  //       const { error: stripeError } = await stripe.redirectToCheckout({
  //         sessionId: result.data.createCheckoutSession.sessionId,
  //       });

  //       if (stripeError) {
  //         setError(stripeError.message || 'An error occurred during checkout.');
  //       }
  //     } else {
  //       setError('Failed to create checkout session.');
  //     }
  //   } catch (err) {
  //     setError(err instanceof Error ? err.message : 'An unexpected error occurred.');
  //   }

  //   setProcessing(false);
  // };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  // return (
  //   <div>
  //     <h2>Checkout for Plan: {planId}</h2>
  //     <form onSubmit={handleSubmit}>
  //       <CardElement />
  //       <button type="submit" disabled={!stripe || processing}>
  //         {processing ? 'Processing...' : 'Pay'}
  //       </button>
  //     </form>
  //   </div>
  // );
};

// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// export function Checkout() {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const planId = queryParams.get('planId');
//   const stripePriceId = queryParams.get('stripePriceId');

//   useEffect(() => {
//     // TODO: Fetch data or perform actions based on the query parameters
//   }, [planId, stripePriceId]);

//   return (
//     <div key={planId}>
//       <h3>{planId}</h3>
//       <p>{stripePriceId}</p>
//     </div>
//   );
// }
