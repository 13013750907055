import React, { useState, ReactNode, useEffect } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { CircularProgress, Box } from '@mui/material';

interface LoadingButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: () => Promise<void>;
  children: ReactNode;
  loadingText?: string;
  minWidth?: string;
  resetDelay?: number;
}

const LoadingButton = ({
  onClick,
  children,
  loadingText = 'Processing...',
  minWidth = '200px',
  resetDelay = 2000,
  type = 'button',
  ...props
}: LoadingButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoading) {
      timer = setTimeout(() => setIsLoading(false), resetDelay);
    }
    return () => clearTimeout(timer);
  }, [isLoading, resetDelay]);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;

    if (type === 'submit') {
      // For form submissions, we don't prevent default
      setIsLoading(true);
    } else if (onClick) {
      event.preventDefault();
      setIsLoading(true);
      try {
        await onClick();
      } catch (error) {
        // Error handling is left to the parent component
      }
    }
    // The button will return to idle state after resetDelay, regardless of success or failure
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <>
          {loadingText}
          <Box component="span" sx={{ ml: 1, display: 'inline-flex', alignItems: 'center' }}>
            <CircularProgress size={24} />
          </Box>
        </>
      );
    }
    return children;
  };

  return (
    <Button
      {...props}
      disabled={isLoading || props.disabled}
      onClick={handleClick}
      sx={{
        minWidth,
        justifyContent: 'space-between',
        ...props.sx,
      }}
      type={type}
    >
      {getContent()}
    </Button>
  );
};

export default LoadingButton;
