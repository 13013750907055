// hooks/useAssets.ts
import { useMemo } from 'react';
import { useAssetsQuery } from './api/assets/assets.generated';

export const useAssets = () => {
  const { data, loading, error, refetch } = useAssetsQuery();

  const assets = useMemo(() => {
    if (!data?.assets.assets) return [];

    return data.assets.assets.map((asset) => ({
      id: asset.id,
      customerId: asset.customerId,
      name: asset.name,
      createdAt: asset.createdAt,
      updatedAt: asset.updatedAt,
      deletedAt: asset.deletedAt,
      videoAsset: {
        fileId: asset.videoAsset.fileId,
        name: asset.videoAsset.name,
        resolution: asset.videoAsset.resolution,
        size: asset.videoAsset.size,
        duration: asset.videoAsset.duration,
      },
    }));
  }, [data]);

  return { assets, loading, error, refetch };
};
