// src/components/Settings/SettingsDisplay.tsx

import React, { memo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { LoadingSpinner } from '../Utils';
import { TestIds } from '../../test-utils';
import { useTrustedDomains } from '../../hooks/useTrustedDomains';
import TrustedDomainsManager from '../Common/TrustedDomainsManager/TrustedDomainsManager';

const SettingsConfig = memo(() => {
  const { domains, domainLimit, isLoading, handleAddDomain, handleRemoveDomain } =
    useTrustedDomains();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box data-testid={TestIds.SETTINGS.CONTAINER}>
      <Typography
        sx={{
          fontSize: { xs: 28, md: 36 },
          fontWeight: 700,
          pb: 3,
          pt: { xs: 3, md: 5.5 },
        }}
        variant="h4"
      >
        Settings
      </Typography>

      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, md: 4 },
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box mb={4}>
          <Typography gutterBottom variant="h6">
            Domain Management
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3 }} variant="body2">
            Configure which domains can host your widget
          </Typography>

          <TrustedDomainsManager
            description="Configure which domains can host your widget"
            domainLimit={domainLimit}
            domains={domains}
            isLoading={isLoading}
            onAddDomain={handleAddDomain}
            onRemoveDomain={handleRemoveDomain}
            title="Domain Management"
          />
        </Box>
      </Paper>
    </Box>
  );
});

export default SettingsConfig;
