// src/components/Common/VideoComponents/VideoSelection.tsx

import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { useAssetsQuery } from '../../../hooks/api/assets/assets.generated';
import { LoadingSpinner } from '../../Utils';
import { TestIds } from '../../../test-utils';
import { VideoSelectionProps } from './types';

export const VideoSelection: React.FC<VideoSelectionProps> = ({
  onSelect,
  updateFormHandlers,
  disabled = false,
  initialSelectedId,
}) => {
  const { data: assetsData, loading } = useAssetsQuery();
  const [selectedAssetId, setSelectedAssetId] = useState(initialSelectedId || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Update selected ID when initial value changes
  useEffect(() => {
    if (initialSelectedId) {
      setSelectedAssetId(initialSelectedId);
    }
  }, [initialSelectedId]);

  useEffect(() => {
    // create new controller mode
    updateFormHandlers?.({
      selection: {
        handleSelect: onSelect,
        selectedAssetId,
        isSubmitting,
      },
    });
  }, [selectedAssetId, isSubmitting, onSelect, updateFormHandlers]);

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const newId = e.target.value;
    setSelectedAssetId(newId);
    // Call onSelect directly for edit mode
    onSelect(newId);
  };

  if (loading) return <LoadingSpinner />;

  const hasAssets = assetsData?.assets?.assets && assetsData.assets.assets.length > 0;

  if (!hasAssets) {
    return (
      <Typography color="text.secondary">
        No existing videos found. Please upload a new video.
      </Typography>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Select Video</InputLabel>
      <Select
        data-testid={TestIds.UPLOAD_FORM.EXISTING_VIDEO_SELECT}
        disabled={disabled}
        onChange={handleSelectChange} // Changed to use new handler
        value={selectedAssetId}
      >
        {assetsData.assets.assets.map((asset) => (
          <MenuItem key={asset.id} value={asset.id}>
            {asset.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
