import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorSnackbar from './ErrorSnackbar';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
}

interface State {
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props;
    if (onError) {
      onError?.(error, errorInfo);
    }
    console.error('Uncaught error:', error, errorInfo);
  }

  private handleCloseSnackbar = () => {
    this.setState({ error: null });
  };

  public render() {
    const { error } = this.state;
    const { children, fallback } = this.props;

    if (error) {
      const isCriticalError = (error as any).isCritical;

      if (isCriticalError) {
        return (
          fallback || (
            <div>
              <h1>We&apos;re sorry, something went wrong.</h1>
              <p>Please try again later or contact support if the problem persists.</p>
            </div>
          )
        );
      }
      return (
        <>
          {children}
          <ErrorSnackbar
            open={!!error}
            message={error.message || 'An unknown error occurred'}
            onClose={this.handleCloseSnackbar}
          />
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
