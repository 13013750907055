// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/components/ControllerBasicInfo.tsx

import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { TextInput } from '../../../../Inputs';
import { ConfigureFormValues } from '../validation';

export const ControllerBasicInfo: React.FC<{
  register: UseFormRegister<ConfigureFormValues>;
  errors: FieldErrors;
}> = ({ register, errors }) => {
  return (
    <Box>
      <Typography gutterBottom variant="subtitle1">
        Basic Information
      </Typography>
      <TextInput
        {...register('name')}
        errors={errors}
        fullWidth
        inputProps={{ maxLength: 50 }}
        label="Controller Name"
        name="name"
      />
    </Box>
  );
};
