// src/components/CreateNewControllerWizardV2/steps/VideoUploadStep/index.tsx

import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Button, Paper } from '@mui/material';
import { defaultDraft, useWizard } from '../../context/WizardContext';
import { useAssetsQuery } from '../../../../hooks/api/assets/assets.generated';
import { VideoUploadForm } from '../../../Common/VideoComponents/VideoUploadForm';
import { VideoSelection } from '../../../Common/VideoComponents/VideoSelection';
import { FormHandlers } from '../../../Common/VideoComponents/types';
import { useCustomerQuery } from '../../../../hooks/api/customer/customer.generated';

const ResourceLimitOverlay = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      backdropFilter: 'blur(4px)',
    }}
  >
    <Paper
      elevation={3}
      sx={{
        p: 4,
        maxWidth: 400,
        width: '90%',
        textAlign: 'center',
      }}
    >
      <Typography gutterBottom variant="h6">
        Controller Limit Reached
      </Typography>
      <Typography color="text.secondary" paragraph>
        You have reached your controller limit. Remove or edit existing controllers.
      </Typography>
      <Button color="primary" href="/controllers" sx={{ mt: 2 }} variant="contained">
        Controllers
      </Button>
    </Paper>
  </Box>
);

const VideoUploadStep: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const { state, dispatch } = useWizard();
  const { data: assetsData } = useAssetsQuery();
  const { data: { customer: customerData } = {} } = useCustomerQuery();
  const isControllerLimitReached = customerData?.resources?.controllers.remaining === 0;

  const updateDraftWithVideo = async (videoData: { fileId: string; name: string }) => {
    dispatch({
      type: 'SET_DRAFT',
      payload: {
        ...defaultDraft,
        stages: [
          {
            ...defaultDraft.stages[0],
            content: {
              ...defaultDraft.stages[0].content,
              id: videoData.fileId,
              type: 'video',
              videoAssetId: videoData.fileId,
              videoAssetName: videoData.name,
              videoAssetUrl: videoData.fileId,
            },
          },
        ],
      },
    });

    // Move to next step
    dispatch({ type: 'SET_STEP', payload: 1 });
  };

  const handleFormUpdate = (handlers: FormHandlers) => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: handlers,
    });
  };

  return (
    <Box>
      {isControllerLimitReached && <ResourceLimitOverlay />}

      <Typography fontSize={24} fontWeight={700} pb={4}>
        Choose a video
      </Typography>

      <Tabs onChange={(_, newValue) => setTabValue(newValue)} sx={{ mb: 3 }} value={tabValue}>
        <Tab label="Upload New" />
        <Tab disabled={!assetsData?.assets?.assets?.length} label="Choose Existing" />
      </Tabs>

      <Box sx={{ mb: 4 }}>
        {tabValue === 0 ? (
          <VideoUploadForm
            disabled={state.isSaving}
            onUploadComplete={updateDraftWithVideo}
            updateFormHandlers={handleFormUpdate}
          />
        ) : (
          <VideoSelection
            disabled={state.isSaving}
            onSelect={async (assetId) => {
              const selectedAsset = assetsData?.assets.assets.find((asset) => asset.id === assetId);
              if (selectedAsset) {
                updateDraftWithVideo({
                  fileId: selectedAsset.videoAsset.fileId,
                  name: selectedAsset.name,
                });
              }
            }}
            updateFormHandlers={handleFormUpdate}
          />
        )}
      </Box>
    </Box>
  );
};

export default VideoUploadStep;
