import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type GetSubscriptionPlansQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSubscriptionPlansQuery = {
  __typename?: 'Query';
  getSubscriptionPlans: {
    __typename?: 'SubscriptionPlansResponse';
    subscriptionPlans: Array<{
      __typename?: 'SubscriptionPlan';
      id: string;
      name: string;
      description: string;
      price?: number | null;
      currency?: string | null;
      stripePriceId: string;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      features: {
        __typename?: 'PlanFeatures';
        viewLimit: number;
        playLimit: number;
        domainLimit: number;
        videoLimit: number;
        controllerLimit: number;
        actionLimit: number;
      };
    }>;
  };
};

export const GetSubscriptionPlansDocument = gql`
  query GetSubscriptionPlans {
    getSubscriptionPlans {
      subscriptionPlans {
        id
        name
        description
        features {
          viewLimit
          playLimit
          domainLimit
          videoLimit
          controllerLimit
          actionLimit
        }
        price
        currency
        stripePriceId
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useGetSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionPlansQuery, GetSubscriptionPlansQueryVariables>(
    GetSubscriptionPlansDocument,
    options,
  );
}
export function useGetSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlansQuery,
    GetSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriptionPlansQuery, GetSubscriptionPlansQueryVariables>(
    GetSubscriptionPlansDocument,
    options,
  );
}
export type GetSubscriptionPlansQueryHookResult = ReturnType<typeof useGetSubscriptionPlansQuery>;
export type GetSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlansLazyQuery
>;
export type GetSubscriptionPlansQueryResult = Apollo.QueryResult<
  GetSubscriptionPlansQuery,
  GetSubscriptionPlansQueryVariables
>;
export function refetchGetSubscriptionPlansQuery(variables?: GetSubscriptionPlansQueryVariables) {
  return { query: GetSubscriptionPlansDocument, variables };
}
