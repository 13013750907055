import { useState, useCallback } from 'react';
import { useGetAnalyticsQuery } from './api/analytics/getAnalytics.generated';

export const useAnalytics = () => {
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => new Date().toISOString().split('T')[0]);
  const [selectedControllerId, setSelectedControllerId] = useState<string>();

  const { data, loading, error, refetch } = useGetAnalyticsQuery({
    variables: {
      input: {
        controllerId: selectedControllerId === '' ? undefined : selectedControllerId,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
      },
    },
    // skip: !selectedControllerId,
  });

  const handleDateChange = useCallback(() => {
    if (selectedControllerId) {
      refetch({
        input: {
          controllerId: selectedControllerId === '' ? undefined : selectedControllerId,
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        },
      });
    }
  }, [selectedControllerId, startDate, endDate, refetch]);

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedControllerId,
    setSelectedControllerId,
    data,
    loading,
    error,
    handleDateChange,
  };
};
