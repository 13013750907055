import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CreateAssetMutationVariables = Types.Exact<{
  input: Types.CreateAssetInputType;
}>;

export type CreateAssetMutation = {
  __typename?: 'Mutation';
  createAsset: {
    __typename?: 'AssetType';
    name: string;
    videoAsset: {
      __typename?: 'UploadedAssetFileResultType';
      fileId: string;
      name: string;
      resolution?: string | null;
      size?: number | null;
      duration?: number | null;
    };
  };
};

export const CreateAssetDocument = gql`
  mutation createAsset($input: CreateAssetInputType!) {
    createAsset(input: $input) {
      name
      videoAsset {
        fileId
        name
        resolution
        size
        duration
      }
    }
  }
`;
export type CreateAssetMutationFn = Apollo.MutationFunction<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument,
    options,
  );
}
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;
