import React from 'react';
import { Box, Button, Container, Typography, Alert, AlertTitle, Link, Paper } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { appConfig } from '../../app-config';

const PaymentCancelled: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'grey.50',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
        >
          {/* Header Section */}
          <ErrorOutlineIcon
            sx={{
              fontSize: 64,
              color: 'error.main',
              mb: 2,
            }}
          />
          <Typography variant="h4" component="h1" gutterBottom align="center" fontWeight="bold">
            Payment Cancelled
          </Typography>
          <Typography variant="body1" color="text.secondary" align="center">
            We noticed there was an issue with your payment
          </Typography>

          {/* Alert Section */}
          <Alert
            severity="error"
            sx={{
              width: '100%',
              mt: 2,
            }}
          >
            <AlertTitle>Payment Processing Error</AlertTitle>
            Your payment could not be processed. This could be due to insufficient funds, expired
            card, or other payment method issues.
          </Alert>

          {/* Support Information */}
          <Box
            sx={{
              bgcolor: 'grey.50',
              p: 2,
              borderRadius: 1,
              width: '100%',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Need help? Contact our support team at{' '}
              <Box
                component="a"
                href="mailto:support@example.com"
                sx={{
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <Link href={`mailto:${appConfig.supportEmail}`} target="_blank" rel="noopener">
                  contact support
                </Link>
              </Box>
            </Typography>
          </Box>

          {/* Action Buttons */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              startIcon={<RefreshIcon />}
              fullWidth
              onClick={() => {
                window.location.href = '/plans';
              }}
              sx={{ py: 1.5 }}
            >
              Try Again
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default PaymentCancelled;
