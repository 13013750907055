import React, { useState, useEffect } from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import { Box, Typography, Popover } from '@mui/material';

interface ColorPickerV2Props {
  color: string;
  defaultColor: string;
  onChangeComplete: (color: string) => void;
  label?: string;
}

const ColorPickerV2: React.FC<ColorPickerV2Props> = ({
  color,
  defaultColor,
  onChangeComplete,
  label,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [localColor, setLocalColor] = useState<string>(color || defaultColor);

  useEffect(() => {
    setLocalColor(color || defaultColor);
  }, [color, defaultColor]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeComplete = (colorResult: ColorResult) => {
    const newColor = colorResult.hex;
    setLocalColor(newColor);
    onChangeComplete(newColor);
  };

  const open = Boolean(anchorEl);

  return (
    <Box mb={2}>
      {label && <Typography variant="body2">{label}</Typography>}
      <Box
        onClick={handleClick}
        sx={{
          width: 36,
          height: 14,
          borderRadius: 1,
          border: '2px solid #fff',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          background: localColor,
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <SketchPicker color={localColor} onChangeComplete={handleChangeComplete} />
        </Box>
      </Popover>
    </Box>
  );
};

export default ColorPickerV2;
