// src/components/EmbedStep/styles.ts

import { styled, Box, Paper, Tabs, Button } from '@mui/material';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));
export const ScrollableContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'data-testId',
})<{ 'data-testId'?: string }>({
  flex: 1,
  overflowY: 'auto',
  paddingBottom: '120px', // Space for support section
});

export const CodeBlock = styled(Box)(({ theme }) => ({
  backgroundColor: '#1e293b',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: '#fff',
  fontFamily: 'monospace',
  position: 'relative',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#441078',
    height: '3px',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#666',
    '&.Mui-selected': {
      color: '#441078',
      fontWeight: '500',
    },
  },
});

export const CopyButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#441078',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#5433d6',
  },
}));

export const SupportBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  zIndex: 1,
  '& .support-container': {
    border: '1px solid #441078',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: 'inline-block',
    position: 'relative',
  },
}));
