// src/components/Profile/components/ProfileInfo.tsx
import { Box, Paper, Divider } from '@mui/material';
import { memo } from 'react';
import { EmailIcon, BusinessIcon } from '../../../assets/icons';
import { ProfileInfoProps } from '../types';
import { UserAvatar } from './UserAvatar';
import { InfoSection } from './InfoSection';

export const ProfileInfo = memo(({ customer }: ProfileInfoProps) => (
  <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
    <UserAvatar
      cancellationDate={customer.currentSubscription?.cancellation?.cancelAt ?? undefined}
      email={customer.email}
      status={customer.status ?? undefined}
    />
    <Divider sx={{ my: 2 }} />
    <Box>
      <InfoSection content={customer.email} icon={<EmailIcon />} title="Email" />
      {customer.companyName && (
        <InfoSection content={customer.companyName} icon={<BusinessIcon />} title="Company" />
      )}
    </Box>
  </Paper>
));
