import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type GetAnalyticsQueryVariables = Types.Exact<{
  input: Types.GetAnalyticsInput;
}>;

export type GetAnalyticsQuery = {
  __typename?: 'Query';
  getAnalytics?: {
    __typename?: 'AnalyticsData';
    customerId: string;
    controllerId: string;
    timestamp: string;
    views: number;
    uniqueViews: number;
    plays: number;
    uniquePlays: number;
    widgetInteractions: any;
    userInteractions: any;
    averageSessionLength: number;
    actions: { __typename?: 'ActionData'; total: number; byType: any };
  } | null;
};

export const GetAnalyticsDocument = gql`
  query GetAnalytics($input: GetAnalyticsInput!) {
    getAnalytics(input: $input) {
      customerId
      controllerId
      timestamp
      views
      uniqueViews
      plays
      uniquePlays
      actions {
        total
        byType
      }
      widgetInteractions
      userInteractions
      averageSessionLength
    }
  }
`;

/**
 * __useGetAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnalyticsQuery, GetAnalyticsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnalyticsQuery, GetAnalyticsQueryVariables>(
    GetAnalyticsDocument,
    options,
  );
}
export function useGetAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticsQuery, GetAnalyticsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnalyticsQuery, GetAnalyticsQueryVariables>(
    GetAnalyticsDocument,
    options,
  );
}
export type GetAnalyticsQueryHookResult = ReturnType<typeof useGetAnalyticsQuery>;
export type GetAnalyticsLazyQueryHookResult = ReturnType<typeof useGetAnalyticsLazyQuery>;
export type GetAnalyticsQueryResult = Apollo.QueryResult<
  GetAnalyticsQuery,
  GetAnalyticsQueryVariables
>;
export function refetchGetAnalyticsQuery(variables: GetAnalyticsQueryVariables) {
  return { query: GetAnalyticsDocument, variables };
}
