import { useCallback, useState } from 'react';
import { useSignAssetUploadCredentialsMutation } from './api/signAssetUploadCredentials/signAssetUploadCredentials.generated';

export const useAssetUpload = () => {
  const [signAssetUploadCredentials] = useSignAssetUploadCredentialsMutation();
  const [uploading, setUploading] = useState(false);

  const uploadAsset = useCallback(
    async (file: File, callback: (fileId: string, name: string) => any) => {
      setUploading(true);

      try {
        const result = await signAssetUploadCredentials({
          variables: {
            name: file.name,
            size: file.size,
            mimeType: file.type,
          },
        });

        if (!result || !result.data) {
          throw new Error('Empty uploadCredentials');
        }

        const { signAssetUploadCredentials: uploadCredentials } = result.data;

        const fields: Record<string, string> = JSON.parse(uploadCredentials.fieldsJson);

        const formData = new FormData();
        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append('file', file);

        await fetch(uploadCredentials.url, {
          method: 'POST',
          body: formData,
        });

        callback(uploadCredentials.fileId, file.name);

        setUploading(false);
      } catch (error) {
        console.error('Upload error:', error);
        setUploading(false);
      }
    },
    [],
  );

  return {
    uploadAsset,
    uploading,
  };
};
