// src/components/Profile/ProfileContainer.tsx

import React, { useState, useCallback } from 'react';
import { useCustomerSettings } from '../../contexts/CustomerSettingsContext';
import { useCreateBillingPortalSessionMutation } from '../../hooks/api/checkoutBillingPortal/checkoutBillingPortal.generated';
import ErrorBoundary from '../Utils/ErrorBoundry';
import ProfileDisplay from './ProfileDisplay';

const ProfileContainer = () => {
  const { customer, isLoading, error: queryError } = useCustomerSettings();
  const [createBillingPortalSessionMutation] = useCreateBillingPortalSessionMutation();
  const [error, setError] = useState<string | null>(null);

  const handleUpdatePayment = useCallback(async () => {
    try {
      const { data } = await createBillingPortalSessionMutation();
      if (data?.createBillingPortalSessionUrl) {
        window.location.href = data.createBillingPortalSessionUrl.billingPortalUrl;
      }
    } catch (err) {
      setError('Failed to create billing session');
    }
  }, [createBillingPortalSessionMutation]);

  return (
    <ErrorBoundary>
      <ProfileDisplay
        customer={customer}
        isLoading={isLoading}
        onUpdatePayment={handleUpdatePayment}
        error={error || queryError?.message}
      />
    </ErrorBoundary>
  );
};

export default ProfileContainer;
