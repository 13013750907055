import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ControllersQuery } from '../../hooks/api/controllers/controllers.generated';

export default function ControllerSelect({
  selectedControllerId,
  controllersData,
  setSelectedControllerId,
}: {
  selectedControllerId?: string;
  controllersData: ControllersQuery;
  setSelectedControllerId: (newValue: string) => void;
}) {
  const handleControllerChange = (event: SelectChangeEvent) => {
    setSelectedControllerId(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="controller-select-label">Select a Controller</InputLabel>
        <Select
          labelId="controller-select-label"
          id="controllerSelect"
          value={selectedControllerId}
          label="Select a Controller"
          onChange={handleControllerChange}
          style={{
            // padding: isLargeScreen ? '0 90px' : '10px 20px',
            // maxWidth: isLargeScreen ? '100%' : '30%',
            padding: '0 90px',
            maxWidth: '100%',
            textTransform: 'none',
            maxHeight: '48px',
            marginRight: '10px',
          }}
        >
          <MenuItem value={undefined}>
            <em>None</em>
          </MenuItem>
          {controllersData?.controllers.controllers.map((controller) => (
            <MenuItem key={controller.id} value={controller.id}>
              {controller.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
