import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type DeleteControllerMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type DeleteControllerMutation = { __typename?: 'Mutation'; deleteController: boolean };

export const DeleteControllerDocument = gql`
  mutation DeleteController($id: String!) {
    deleteController(id: $id)
  }
`;
export type DeleteControllerMutationFn = Apollo.MutationFunction<
  DeleteControllerMutation,
  DeleteControllerMutationVariables
>;

/**
 * __useDeleteControllerMutation__
 *
 * To run a mutation, you first call `useDeleteControllerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteControllerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteControllerMutation, { data, loading, error }] = useDeleteControllerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteControllerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteControllerMutation,
    DeleteControllerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteControllerMutation, DeleteControllerMutationVariables>(
    DeleteControllerDocument,
    options,
  );
}
export type DeleteControllerMutationHookResult = ReturnType<typeof useDeleteControllerMutation>;
export type DeleteControllerMutationResult = Apollo.MutationResult<DeleteControllerMutation>;
export type DeleteControllerMutationOptions = Apollo.BaseMutationOptions<
  DeleteControllerMutation,
  DeleteControllerMutationVariables
>;
