// src/components/EditController/EditController.tsx
import React, { useEffect } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { useEditContext } from '../context/EditContext';
import { WidgetPreview } from '../../CreateNewControllerWizardV2/shared/WidgetPreview';
import { VideoReplaceSection } from './VideoReplaceSection';
import { EditNavigation } from './EditNavigation';
import { EditTabs } from './EditTabs';
import { ClosedControllerSection } from './ClosedControllerSection';
import { OpenControllerSection } from './OpenControllerSection';

const TabPanel: React.FC<{ children: React.ReactNode; value: number; index: number }> = ({
  children,
  value,
  index,
}) => (
  <div
    aria-labelledby={`edit-tab-${index}`}
    hidden={value !== index}
    id={`edit-tabpanel-${index}`}
    role="tabpanel"
  >
    {value === index && children}
  </div>
);

export const EditContent: React.FC = () => {
  const { state, dispatch } = useEditContext();

  useEffect(() => {
    return () => {
      // Clear context on unmount
      dispatch({ type: 'CLEAR_CHANGES' });
    };
  }, []);

  // const previewState = {
  //   controllerId: state.controllerId,
  //   previewKey: state.previewKey,
  //   isSaving: state.isSaving,
  // };

  return (
    <Box sx={{ mb: 8 }}>
      <EditTabs />

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <TabPanel index={0} value={state.activeTab}>
            <VideoReplaceSection />
          </TabPanel>
          <TabPanel index={1} value={state.activeTab}>
            <ClosedControllerSection />
          </TabPanel>
          <TabPanel index={2} value={state.activeTab}>
            <OpenControllerSection />
          </TabPanel>
        </Grid>

        {/* <Grid item md={6} xs={12}>
          <Paper
            sx={{
              p: 3,
              position: 'sticky',
              top: 24,
              height: 'calc(100vh - 200px)',
              overflow: 'hidden',
            }}
          >
            <WidgetPreview previewState={previewState} />
          </Paper>
        </Grid> */}
      </Grid>

      <EditNavigation />
    </Box>
  );
};
