// src/components/CreateNewControllerWizardV2/components/WizardNavigation.tsx

import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { TestIds } from '../../../test-utils';
import { useWizardNavigation } from '../hooks/useWizardNavigation';
import { useWizard } from '../context/WizardContext';

export const WizardNavigation: React.FC = () => {
  const { currentStep, canGoBack, goToNextStep, goToPreviousStep } = useWizardNavigation();
  const { state } = useWizard();

  const uploadForm = state.forms?.upload;
  const selectionForm = state.forms?.selection;
  const configureForm = state.forms?.configure;

  const isSubmitting =
    uploadForm?.isSubmitting || selectionForm?.isSubmitting || configureForm?.isSubmitting;

  const handleNext = () => {
    switch (currentStep) {
      case 0:
        if (state.activeTab === 0) {
          uploadForm?.handleSubmit?.();
        } else {
          selectionForm?.handleSelect?.(selectionForm.selectedAssetId);
        }
        break;

      case 2:
        if (configureForm?.handleSubmit) {
          configureForm.handleSubmit();
        }
        break;

      default:
        goToNextStep();
    }
  };

  const isNextDisabled = () => {
    switch (currentStep) {
      case 0:
        return (
          (state.activeTab === 0 && !uploadForm?.handleSubmit) ||
          (state.activeTab === 1 && !selectionForm?.selectedAssetId)
        );

      case 2:
        return !configureForm?.isValid || configureForm?.isSubmitting;

      default:
        return false;
    }
  };

  const renderButton = () => (
    <LoadingButton
      color="primary"
      data-testid={TestIds.CONTROLLER_WIZARD.NEXT_STEP_BUTTON}
      disabled={isNextDisabled()}
      loading={isSubmitting}
      onClick={handleNext}
      sx={{
        textTransform: 'none',
        px: 9.5,
        py: 1.6,
        fontWeight: 700,
      }}
      variant="contained"
    >
      {currentStep === 2 ? 'Create controller' : (currentStep === 3 && 'Finish') || 'Next'}
    </LoadingButton>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        pt: 2,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
        p: 2,
        zIndex: 1000,
        ml: { sm: '240px' },
      }}
    >
      <Box sx={{ maxWidth: 1200, mx: 'auto', width: '100%', display: 'flex' }}>
        {currentStep !== 1 && canGoBack && (
          <Button color="primary" onClick={goToPreviousStep} sx={{ mr: 1 }} variant="outlined">
            Back
          </Button>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        {renderButton()}
      </Box>
    </Box>
  );
};
