import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type AssetsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AssetsQuery = {
  __typename?: 'Query';
  assets: {
    __typename?: 'AssetsQueryResultType';
    assets: Array<{
      __typename?: 'AssetType';
      id: string;
      customerId: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      videoAsset: {
        __typename?: 'UploadedAssetFileResultType';
        fileId: string;
        name: string;
        resolution?: string | null;
        size?: number | null;
        duration?: number | null;
      };
    }>;
  };
};

export const AssetsDocument = gql`
  query assets {
    assets {
      assets {
        id
        customerId
        name
        createdAt
        updatedAt
        deletedAt
        videoAsset {
          fileId
          name
          resolution
          size
          duration
        }
      }
    }
  }
`;

/**
 * __useAssetsQuery__
 *
 * To run a query within a React component, call `useAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<AssetsQuery, AssetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
}
export function useAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssetsQuery, AssetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetsQuery, AssetsQueryVariables>(AssetsDocument, options);
}
export type AssetsQueryHookResult = ReturnType<typeof useAssetsQuery>;
export type AssetsLazyQueryHookResult = ReturnType<typeof useAssetsLazyQuery>;
export type AssetsQueryResult = Apollo.QueryResult<AssetsQuery, AssetsQueryVariables>;
export function refetchAssetsQuery(variables?: AssetsQueryVariables) {
  return { query: AssetsDocument, variables };
}
