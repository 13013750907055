// src/components/Usage/components/PlanInfo.tsx
import { Box, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { memo } from 'react';
import { PlanInfoProps } from '../types';

export const PlanInfo = memo(({ planName, startDate, endDate }: PlanInfoProps) => (
  <Box sx={{ mb: { xs: 2, md: 4 } }}>
    <Typography gutterBottom variant="h4">
      Analytics
    </Typography>
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h6">Plan: {planName}</Typography>
      <Typography variant="h6">Start Date: {format(parseISO(startDate), 'MM/dd/yyyy')}</Typography>
      <Typography variant="h6">End Date: {format(parseISO(endDate), 'MM/dd/yyyy')}</Typography>
    </Box>
  </Box>
));
