// src/components/CreateNewControllerWizardV2/steps/StyleStep/index.tsx

import React from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { BorderConfig } from './components/BorderConfig';
import { BubbleConfig } from './components/BubbleConfig';
import { PositionConfig } from './components/PositionConfig';
import { WidgetPreview } from '../../shared/WidgetPreview';
import { useWizard } from '../../context/WizardContext';
import { WidgetSizeConfig } from '../../../Common/StyleComponents/WidgetSizeConfig';

const StyleStep: React.FC = () => {
  const { state, dispatch } = useWizard();

  const previewState = {
    draftController: state.draft ?? undefined,
    controllerId: state.controllerId!,
    previewKey: state.previewKey,
    isSaving: state.isSaving,
  };

  const handleStyleUpdate = (changes: any) => {
    // Update both the legacy styleConfig and the draft controller
    dispatch({ type: 'UPDATE_STYLE', payload: changes });

    // Update the draft controller's layout
    if (state.draft) {
      dispatch({
        type: 'UPDATE_DRAFT',
        payload: {
          layout: {
            type: 'widget',
            widget: {
              design: {
                minified: {
                  ...state.draft.layout.widget.design.minified,
                  ...changes,
                },
              },
            },
          },
        },
      });
    }
  };

  return (
    <Grid container spacing={3}>
      {/* Form Column */}
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          {/* Basic Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <WidgetSizeConfig onStyleChange={handleStyleUpdate} />
          </Paper>

          {/* Border Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <BorderConfig onStyleChange={handleStyleUpdate} />
          </Paper>

          {/* Position Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <PositionConfig onStyleChange={handleStyleUpdate} />
          </Paper>

          {/* Bubble Settings */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <BubbleConfig onStyleChange={handleStyleUpdate} />
          </Paper>
        </Stack>
      </Grid>

      {/* Preview Column */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            position: 'sticky',
            top: 24,
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
          }}
        >
          <WidgetPreview previewState={previewState} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StyleStep;
